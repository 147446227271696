.rounded-circle-testimonials {
    width: 230px;
    height: 220px;
    margin: 0 auto;
    border-radius: 50%!important;
    object-fit: cover;           // prevents face images from being vertically stretched or horizontally squashed
    @media only screen and (max-width: 960px) {
        width: 200px;
        height: 200px; }
    @media only screen and (max-width: 768px) {
        width: 180px;
        height: 180px; }
    @media only screen and (max-width: 320px) {
        width: 100px;
        height: 100px; } }

.header-about-us {
    h1, h2 {
        color: white;
        text-transform: uppercase;
        text-shadow: 1px 2px #ffbd59;
        letter-spacing: 2px;
        font-size: 55px;
        font-family: $font-title;
        font-weight: 700;
        @media screen and (min-width: 580px) and (max-width: 768px) {
            font-size: 40px; }
        @media only screen and (max-width: 600px) {
            font-size: 25px; } } }

.about-us-container {
    overflow-x: hidden;
    h2,h3,h4 {
        font-size: xx-large;
        color: $darkblue-color;
        font-family: $font-header-title; }

    .team-member {
        @media only screen and (max-width: 376px) {
            margin-bottom: 2em; } } }

.about-us-title {
    text-align: center; }

.reveal {
    position: relative;
    transform: translateY(100px);
    opacity: 0;
    transition: all 1.5s ease;
    padding: 1em; }

.reveal.active {
    transform: translateY(0);
    opacity: 1; }
.staff-spotlight {
    padding-top: 5em;
    @media only screen and (max-width: 376px) {
        padding-top: 2em; }
    .carousel-staff-spotlight {
        margin: 0 auto;
        .col {
            padding: 10px; }
        .card {
            border-radius: 30px !important;
            box-shadow: 0 0 10px #8a8a8a57;
            padding-top: 2em;

            .card-title {
                text-align: center;
                margin: 0; }
            p {
                text-align: center; }

            .fa-quote-left {
                color: #369aacb5;
                font-size: 25px;
                position: relative;
                bottom: 10px; }

            .fa-quote-right {
                color: #369aacb5;
                font-size: 25px;
                position: relative;
                top: 15px; } } } }

.about-teams {
    padding-top: 5em;
    .carousel-teams {
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        padding: 0;

        .card-team {
            padding-bottom: 1em;
            padding: 7px;

            span {
                color: #fff;
                font-family: sans-serif;
                position: absolute;
                text-align: right;
                width: 100%;
                padding: 15px 25px 0px 5px;
                font-weight: 600;
                font-size: $defaultfont-size;
                font-family: $font-header-title;
                line-height: 1.1em; }

            img {
                border-radius: 10px;
                @media only screen and (max-width: 426px) {
                    width: 70%;
                    margin: 0 auto; }

                @media only screen and (min-width: 1025px) {
                    display: block;
                    text-align: center;
                    // padding: 10px
                    // margin-bottom: 10px
                    // height: 228px
                    // width: 350px
                    -webkit-transition: all 200ms ease-in;
                    -webkit-transform: scale(1);
                    -ms-transition: all 200ms ease-in;
                    -ms-transform: scale(1);
                    -moz-transition: all 200ms ease-in;
                    -moz-transform: scale(1);
                    transition: all 100ms ease-in;
                    transform: transform .4;

                    &:hover {
                        box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
                        z-index: 2;
                        -webkit-transition: all 200ms ease-in;
                        -webkit-transform: scale(1.5);
                        -ms-transition: all 200ms ease-in;
                        -ms-transform: scale(1.5);
                        -moz-transition: all 200ms ease-in;
                        -moz-transform: scale(1.5);
                        transition: all 200ms ease-in;
                        transform: scale(1.07); }

                    &:hover:before {
                        width: 40%;
                        height: 50%;
                        transition: all 300ms ease-in; }

                    &:hover:after {
                        width: 30%;
                        height: 40%;
                        transition: all 300ms ease-in; } } } } } }

.about-us-accomplishments {
    @media only screen and (min-width: 1024px) {
        padding-top: 5em; }

    @media only screen and (max-width: 1024px) {
        padding-top: 2em; } }


.about-us-locations {
    @media only screen and (min-width: 1024px) {
        padding: 3em 0 5em 0; }

    @media only screen and (max-width: 1024px) {
        padding: 2em 0 2em 0; }

    .wrapper {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; }
    .img-container {
        border-right: 2px solid $gold-color; }
    .gold-color {
        color: $gold-color; }

    .location-images {
        padding: 2em;
        .card {
            overflow: hidden;
            margin: auto;
            box-shadow: 0 8px 16px rgba(black, .45);

            img {
                transition: 0.5s all ease-in-out;
                @media screen and (min-width: 540px) {
                    height: 280px; } }

            &:hover {
                img {
                    transform: scale(1.1); } }

            .card-body {
                background-color: $mutedblue-color;
                padding: 10px;
                text-align: center;
                position: relative;

                .card-title {
                    color: #fff;
                    margin: 0; } } } }

    .carousel-about-us-locations {
        .img-container {
            padding: 0;

            img {
                max-height: 375px; } }
        .card-title {
            text-align: center;
            background-color: $mutedblue-color;
            color: #fff;
            margin: 0; } } }


.about-us-values {
    // background-color: $darkblue-color
    color: #fff;
    overflow: hidden;
    // padding: 100px 0
    text-align: center;

    .row {
        margin-right: 0;
        margin-left: 0;
        // justify-content: center

        .custom-slick-btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 0 8px;
            width: 48px;
            height: 48px;
            border-radius: 48px;
            color: #0e1318;
            box-shadow: none;
            background: #fff;
            font-size: .875rem;
            line-height: 1;
            border: 1px solid #e9ecef;
            cursor: pointer; } }

    .container {
        @media only screen and (max-width: 426px) {
            padding: 0 15px; } }

    h3,h4 {
        font-family: $font-header-title; }

    .card-values {
        transition-duration: 0.3s;

        .card-title {
            @media only screen and (max-width: 768px) {
                font-size: 1.5rem; } }

        .card-body {
            padding: 10px;
            background-color: $mutedblue-color;
            color: #fff; }

        .item {
            width: calc(16.6667%);
            white-space: normal; }

        .card {
            border-radius: 1rem;
            padding: 15px 20px 15px;
            margin-left: 0 !important;
            background-color: $mutedblue-color;
            color: #ccc; }

        .card-title {
            font-family: $font-header-title;
            font-weight: bold;
            font-size: 32px;
            color: #fff;
            margin: 0;
            line-height: 93%; } }


    .image-map-div {
        margin-bottom: 100px;
        @media only screen and (max-width: 840px) {
            margin-bottom: 150px; }
        @media only screen and (max-width: 769px) {
            padding: 0; }

        .table-core-value {
            border: 0px solid transparent;
            @media only screen and (max-width: 1025px) {
                margin-top: 40px; }

            img {
                margin-left: 5px; }
            td.desc-col {
                width: 280px;
                @media only screen and (max-width: 960px) {
                    width: 150px; }
                @media only screen and (max-width: 500px) {
                    width: 90px;
                    padding: 0 5px 0 5px; }
                @media only screen and (max-width: 376px) {
                    width: 88px; } }

            td {
                padding: 5px;

                .row.contents {
                    position: relative;
                    @media only screen and (min-width: 1025px) {
                        display: block;
                        text-align: center;
                        // padding: 10px
                        // margin-bottom: 10px
                        // height: 228px
                        // width: 350px
                        -webkit-transition: all 200ms ease-in;
                        -webkit-transform: scale(1);
                        -ms-transition: all 200ms ease-in;
                        -ms-transform: scale(1);
                        -moz-transition: all 200ms ease-in;
                        -moz-transform: scale(1);
                        transition: all 100ms ease-in;
                        transform: transform .4;

                        &:hover {
                            z-index: 2;
                            -webkit-transition: all 200ms ease-in;
                            -webkit-transform: scale(1.5);
                            -ms-transition: all 200ms ease-in;
                            -ms-transform: scale(1.1);
                            -moz-transition: all 200ms ease-in;
                            -moz-transform: scale(1.1);
                            transition: all 200ms ease-in;
                            transform: scale(1.1); }

                        &:hover:before {
                            width: 40%;
                            height: 50%;
                            transition: all 300ms ease-in; }

                        &:hover:after {
                            width: 30%;
                            height: 40%;
                            transition: all 300ms ease-in; } } }


                .title {
                    font-weight: bold;
                    font-size: 22px;
                    padding: 0;
                    @media only screen and (max-width: 1025px) {
                        font-size: large; }
                    @media only screen and (max-width: 769px) {
                        font-size: medium; }
                    @media only screen and (max-width: 500px) {
                        font-size: small; }
                    @media only screen and (max-width: 376px) {
                        font-size: x-small;
                        line-height: 1; } }

                .desc {
                    display: block;
                    position: absolute;
                    font-size: medium;
                    color: #63696F;
                    @media only screen and (max-width: 1025px) {
                        display: block;
                        font-size: smaller;
                        line-height: 1.3; }
                    @media only screen and (max-width: 500px) {
                        font-size: x-small; }
                    @media only screen and (max-width: 376px) {
                        line-height: 1.2; }
                    @media only screen and (max-width: 320px) {
                        font-size: xx-small; } } }


            .entrepreneurial, .positiveCandor {
                vertical-align: middle;

                .contents {
                    bottom: 50px;

                    @media only screen and (max-width: 1025px) {
                        bottom: 35px; }

                    @media only screen and (max-width: 840px) and (min-width: 768px) {
                        bottom: 40px; }
                    @media only screen and (max-width: 426px) {
                        bottom: 60px; } }

                @media only screen and (max-width: 1025px) {
                    .desc {
                        margin-top: 20px; } } }



            .positiveCandor {
                text-align: right;

                .title {
                    color: #FFBD59;

                    @media only screen and (max-width: 426px) {
                        line-height: 1;
                        top: -6px;
                        position: relative; } }

                .desc {
                    //margin-top: 2em
                    padding: 0;

                    @media only screen and (max-width: 1025px) {
                        margin-top: 2em; }

                    @media only screen and (max-width: 376px) {
                        margin-top: 1em; } } }

            .entrepreneurial {
                text-align: left;

                .title {
                    color: #ff86AB;

                    @media only screen and (max-width: 769px) {
                        line-height: 1;
                        top: -6px;
                        position: relative; } }

                .desc {
                   // margin-top: 2em
                   //adding: 0

                   //media only screen and (max-width: 1025px)
 } }                   //   margin-top: 2em

            .teamworkdiv {
                text-align: right;
                left: -2px;

                @media only screen and (max-width: 1024px) {
                    .contents {
                        left: 40px;

                        @media only screen and (max-width: 960px) {
                            left: 0; }
                        @media only screen and (max-width: 769px) {
                            left: -10px; }

                        @media only screen and (max-width: 426px) {
                            left: -15px; }

                        @media only screen and (max-width: 376px) {
                            left: -5px; }

                        @media only screen and (max-width: 320px) {
                            left: 4px; } } }

                @media only screen and (max-width: 500px) {
                    padding: 0 0 0 14px; }
                @media only screen and (max-width: 376px) {
                    padding: 5px; }

                .title {
                    bottom: 45px;
                    position: absolute;
                    color: #369AAC;

                    @media only screen and (max-width: 320px) {
                        bottom: 40px; } }

                .desc {
                    top: -3.2em;
                    padding: 0;

                    @media only screen and (max-width: 960px) {
                        top: -5.2em; } } }


            .TalentAndPursuitExcellence {
                padding-bottom: 1em;
                .contents {
                    top: -25px;

                    @media only screen and (max-width: 1025px) {
                        top: -70px; }

                    @media only screen and (max-width: 960px) {
                        top: -48px; }
                    @media only screen and (max-width: 500px) {
                        top: -45px; }

                    @media only screen and (max-width: 376px) {
                        top: -65px; }

                    .title {
                        color: #00BFD3; }
                    .desc {
                        //margin-top: 2em

                        @media only screen and (max-width: 1025px) {
                            margin-top: 2em; }

                        @media only screen and (max-width: 960px) {
                            top: -4px; }
                        @media only screen and (max-width: 376px) {
                            top: -6px; }
                        @media only screen and (max-width: 320px) {
                            top: 3px; } } } }



            .passiondiv {
                .contents {
                    text-align: left;
                    right: -2px;

                    @media only screen and (max-width: 1024px) {
                        right: 40px; }

                    @media only screen and (max-width: 960px) {
                        left: 5px; }

                    @media only screen and (max-width: 840px) and (min-width: 768px) {
                        left: 10px; }

                    @media only screen and (max-width: 426px) {
                        left: 10px; }
                    @media only screen and (max-width: 376px) {
                        left: -1px; } }

                .title {
                    bottom: 45px;
                    position: absolute;
                    color: #d56d86;

                    @media only screen and (max-width: 320px) {
                        bottom: 40px; } }
                .desc {
                    top: -3.2em;
                    padding: 0;

                    @media only screen and (max-width: 960px) {
                        top: -5.2em; }
                    @media only screen and (max-width: 840px) {
                        top: -5em; } } }

            .passiondiv, .teamworkdiv {
                @media only screen and (max-width: 1025px) {
                    top: 13px; }

                @media only screen and (max-width: 960px) {
                    top: 5px; }

                @media only screen and (max-width: 840px) {
                    top: 0; }

                @media only screen and (max-width: 320px) {
                    top: 22px; }


                .desc {
                    top: -48px;
                    @media only screen and (max-width: 769px) {
                        top: -3.5em; }
                    @media only screen and (max-width: 640px) {
                        top: -5em; }
                    @media only screen and (max-width: 376px) {
                        top: -4.1em; } } }



            // [data-name='entrep'].contents:hover
            //     bottom: 180px
 } } }            //     transition: all .2s ease



.animation {
    text-align: center; }

.fade-in-left {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-transition: all .8s ease-in-out;
    transition: all .8s ease-in-out; }


.what-we-celebrate {
    overflow: hidden;

    @media only screen and (min-width: 1024px) {
        padding: 3em; }
    @media only screen and (max-width: 1024px) {
        padding: 2em; }


    p {
        padding: 50px; }

    h3,h4 {
        font-family: $font-header-title; }
    ul {
        padding: 0; }

    .col.item {
        padding: 1em;
        margin: 10px;
        @media only screen and (max-width: 426px) {
            padding: 0.5em; } } }

.success-story-div {

    h3,h4 {
        font-family: $font-header-title; }

    .card {
        margin-bottom: 10px;
        width: 100%;
        // TODO: Jekyll will not accept this or any rgb() invocation with four arguments for some reason?
        // box-shadow: 0 0 20px rgb(28 229 255 / 20%)
        // box-shadow: 0 0 20px rgb(28, 229, 255, 0.2p)   also not accepted?
        box-shadow: 0 0 20px rgb(28, 229, 255);
        border-radius: 30px !important;
        .card-body {
            @media only screen and (max-width: 376px) {
                padding: 10px; }
            p {
                text-align: center;
                @media only screen and (max-width: 376px) {
                    font-size: 16px; } }
            .card-title {
                text-align: center;
                color: $brightblue-color; } }
        .author-img-container {
            padding-top: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            position: relative;
            @media only screen and (max-width: 1024px) and (min-width: 800px) {
                padding-top: 55%; }
            @media only screen and (max-width: 376px) {
                padding-top: 60%; }

            .book-img-div {
                text-align: right;
                position: absolute;
                top: 15%;
                align-items: center;
                .book-img {
                    width: 97px;
                    height: auto;
                    margin-right: 5em;
                    position: absolute;
                    @media only screen and (max-width: 1024px) and (min-width: 800px) {
                        width: 85px;
                        margin-right: 3em; }
                    @media only screen and (max-width: 769px) {
                        width: 65px;
                        margin-right: 2em; }
                    @media only screen and (max-width: 376px) {
                        width: 60px;
                        margin-right: 1em; } }

                .home-rounded-img {
                    width: 230px;
                    height: 220px;
                    margin: 0 auto;
                    border-radius: 50%!important;
                    object-fit: cover;
                    @media only screen and (max-width: 1024px) {
                        width: 200px;
                        height: 190px; }
                    @media only screen and (max-width: 769px) {
                        width: 150px;
                        height: 140px; }
                    @media only screen and (max-width: 320px) {
                        width: 135px;
                        height: 125px; } } } } }


    .row-author {
        @media only screen and (min-width: 961px) {
            padding-top: 2em; }
        @media only screen and (max-width: 960px) {
            padding-top: 1em; }

        .author-img {
            padding: 1em; }
        .paragraph {
            align-content: center;
            text-align: justify;
            padding: 2em; }
        p {
            font-family: $font-body; } } }

.celebrate-title {
    font-family: $font-header-title;
    font-weight: bolder;
    font-size: larger;
    // font-variant: small-caps
    color: $brightblue-color; }

.celebrate-list {
    list-style: none;
    padding: 0 0 0.6em 0; }

.home-testimonials {
    .card-body {
        @media only screen and (max-width: 376px) {
            padding: 0; } }

    .img-container {
        position: relative;
        width: 100%;
        padding-top: 54.25%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        @media only screen and (max-width: 1024px) and (min-width: 900px) {
            padding-top: 59%; }

        @media only screen and (max-width: 940px)  and (min-width: 400px) {
            padding-top: 77%; }

        @media only screen and (max-width: 376px) {
            padding-top: 93.25%; }

        img {
            position: absolute;
            top: 0;
            width: 275px;
            height: auto;

            @media only screen and (max-width: 1024px) {
                width: 240px; } } }

    .card-title {
        text-align: center;
        color: $brightblue-color;
        font-size: $mediumfont-size; }
    .card-text {
        text-align: center; } }


.slick-btns {
    .custom-slick-btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0 8px;
        width: 48px;
        height: 48px;
        border-radius: 48px;
        color: #ffffff;
        box-shadow: none;
        background: transparent;
        font-size: .875rem;
        line-height: 1;
        border: 0 solid transparent;
        cursor: pointer;
        i {
            font-size: 18px; } } }
