.uk-homepage-header {
    background-position: center;
    background-repeat: no-repeat;
    height: 285px;
    margin-top: 40px;
    @media screen and (max-width: 1024px) {
        height: 300px; }

    @media screen and (max-width: 960px) {
        height: 400px;

        .home-title {
            h1 {
                span {
                    white-space: nowrap; } } }

        padding: 0;
        height: 350px {
            padding: 5px 20px 5px 20px; }
        a {
            .btn {
                font-size: 15px; } } }
    @media screen and (min-width: 320px) and (max-width: 580px) {
        height: 450px; }

    .home-title {
        font-family: $font-header-main;
        position: absolute;
        p {
            display: inline-block;
            vertical-align: top; }
        h1 {
            font-size: 4em;
            line-height: normal;
            display: inline-block;
            vertical-align: middle;
            @media screen and (min-width: 320px) and (max-width: 580px) {
                font-size: 3em; } }
        a.home-work-with-us {
            img {
                margin: auto;
                height: 60px;
                width: auto;
                @media screen and (min-width: 320px) and (max-width: 580px) {
                    height: 40px;
                    width: auto; } } }
        .title-below {
            margin-top: -10px; }
        .title-desc {
            font-size: larger; }
        .word-switch {
            position: relative;
            width: 100%;
            opacity: 0;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            .letter.out,.letter.up {
                display: none; } }
        .publish {
            color: #FFBD59; }
        .market {
            color: #369AAC; }
        .distribute {
            color: #D56D86; }

        .letter {
            display: inline-block;
            position: relative;
            float: center;
            transform: translateZ(25px);
            transform-origin: 50% 50% 25px;
            &.out {
                transform: rotateX(90deg);
                transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
            &.behind {
                transform: rotateX(-90deg); }
            &.in {
                transform: rotateX(0deg);
                transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275); } } } }

#uk-tellwell-rating-wrapper {
    height: auto;
    background-color: $brightblue-color;
    text-align: center;
    padding: 20px;
    font-family: $font-title;
    @media screen and (max-width: 540px) {
        padding: 0; }
    .tellwell-ratings {
        padding: 10px;
        color: white;
        h1 {
            color: white;
            @media screen and (max-width: 540px) {
                margin: 0; } } } }

.uk-tellwell-publishing-home-content {
    h1, h2, h3, h4, h5, h6 {
        font-family: $font-title; } //$font-header-main
    p {
        font-family: $font-body; } }

.uk-service-intro-text, .uk-whyChooseSelf-publishing {
    h1, h6 {
        text-align: center; }
    h4 {
        text-align: center;
        color: $brightblue-color; }
    p {
        @media screen and (max-width: 540px) {
            padding: 0; }
        text-align: center;
        color: #7E7E7E;
        font-size: $mediumfont-size;
        padding: 0 100px 0 100px; }

    h6 {
        line-height: 2.5em;
        color: $brightblue-color;
        letter-spacing: 0.1em; } }

.uk-homepage-services-card {
    // TODO: right edge has a border but not the left; looks great on mobile but on desktop looks slightly uneven when left edge in first column doesn't have a line; barely noticeable but would be nice to adjust? (low prio)
    border-right-style: inset;
    padding: 20px;
    margin-bottom: 15px;
    margin-top: 15px;
    i {
        color: $brightblue-color; }
    .service-title {
        padding-left: 15px;
        padding-right: 15px; }
    .service-description {
        font-size: 16px;
        font-weight: 400;
        color: #696969;

        p {
            font-size: $defaultfont-size; }

        .service-footer {
            color: $brightblue-color;
            font-weight: bold;
            position: absolute;
            bottom: 0; } } }


.uk-why-tellwell {
    background-color: #DAFBFF;
    .uk-why-tellwell-cards {
        .card-block {
            background-color: $brightblue-color;
            height: 250px;
            @media screen and (max-width: 540px) {
                height: auto; }
            @media screen and (min-width: 768px) and (max-width: 1024px) {
                height: 330px; }
            margin: 10px;
            padding: 30px 10px 30px 10px;
            .wrapper {
                display: table;
                width: 70px;
                height: 70px;
                color: white;
                border-style: solid;
                border-radius: 999px;
                font-size: 1.3rem;
                i {
                    display: table-cell;
                    vertical-align: middle;
                    text-align: center;
                    color: white; } }
            .card-title {
                color: #FFFFFF; }
            .card-text {
                color: #FFFFFFD1;
                font-size: $mediumfont-size;
                font-weight: 400; } } } }

.uk-self-pub-vs-traditional {
    font-family: Nunito Sans;
    h5 {
        color: $brightblue-color;
        letter-spacing: 0.3em; }
    ul li {
        font-size: $mediumfont-size; }
    .img-section {
        img {
            margin-top: -50px;
            @media screen and (max-width: 540px) {
                margin-top: 0; } } } }

