.custom-col-sm-12 {
    @media only screen and (max-width: 800px) {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%; } }
.header-nav-warning {
    background-color: beige;
    font-size: small;
    color: red;
    text-align: center; }

.homepage-header {
    background-position: center;
    background-repeat: no-repeat;
    height: 230px;
    margin-top: 40px;
    @media screen and (max-width: 1024px) {
        height: 250px; }

    @media screen and (max-width: 960px) {
        .home-title {
            h1 {
                span {
                    white-space: nowrap; } } }

        padding: 0;
        height: 350px {
            padding: 5px 20px 5px 20px; }
        a {
            .btn {
                font-size: 15px; } } }
    @media screen and (max-width: 800px) {
        height: 265px; }
    @media screen and (min-width: 320px) and (max-width: 640px) {
        height: 290px; }

    .home-title {
        font-family: $font-header-main;
        position: absolute;

        .switch-div {
            min-height: 92px; }

        p {
            display: inline-block;
            vertical-align: top; }
        h1 {
            font-size: 4em;
            line-height: normal;
            display: inline-block;
            vertical-align: middle;
            min-height: 92px;
            @media screen and (max-width: 1530px) {
                font-size: 55px; }
            @media screen and (min-width: 320px) and (max-width: 580px) {
                font-size: 3em; } }
        a.home-work-with-us {
            img {
                margin: auto;
                height: 60px;
                width: auto;
                @media screen and (min-width: 320px) and (max-width: 580px) {
                    height: 40px;
                    width: auto; } } }
        .title-below {
            margin-top: -10px; }
        .title-desc {
            font-size: larger; }
        .word-switch {
            position: fixed;
            width: 100%;
            opacity: 0;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            min-height: 92px;
            animation: fadeIn 1s ease-in forwards;
            .letter.out,.letter.up {
                display: none; } }
        .publish {
            color: #FFBD59; }
        .market {
            color: #369AAC; }
        .distribute {
            color: #D56D86; }
        .edit {
            color: $mutedblue-color; }
        .write {
            color: $gold-color; }

        .letter {
            display: inline-block;
            position: relative;
            float: center;
            transform: translateZ(25px);
            transform-origin: 50% 50% 25px;
            transform: scale(1);
            &.out {
                transform: rotateX(90deg);
                transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
            &.behind {
                transform: rotateX(-90deg); }
            &.in {
                transform: rotateX(0deg);
                transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275); } } } }

@keyframes pop-out {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }

.carousel-authors {
    padding: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease;
    height: auto;
    overflow: hidden;
    -webkit-transition: opacity 1s ease;
    @media screen and (max-width: 1540px) {
        max-width: 100%;
        height: auto;
 }        // margin: 0 auto
    @media screen and (max-width: 440px) {
        height: auto; }

    .slick-list {
        overflow: visible; }
    .img-container {
        height: auto;
        max-height: 100%;
        max-width: 100%;

        @media screen and (max-width: 1440px) {
            height: auto; }
        @media screen and (max-width: 640px) {
            max-height: 300px;
            max-width: 100%; }
        @media screen and (max-width: 545px) {
            max-height: 100%;
            height: auto; } }
    img {
        width: 100%;
        height: auto;

        @media screen and (max-width: 1440px) {
            height: auto; } }

    .card {
        @media screen and (max-width: 1540px) {
            width: auto; }
        .card-body {
            text-align: center;
            background-color: black;
            position: relative;
            top: 0;
            padding: 1.30rem; }
        .card-title {
            margin-bottom: 0;
            color: white;
            font-weight: 400;
            font-size: medium;
            @media screen and (max-width: 545px) {
                font-size: 14px; } } } }
.carousel-authors.slick-initialized {
    visibility: visible;
    opacity: 1; }
#tellwell-rating-wrapper {
    height: auto;
    background-color: $mutedblue-color;
    text-align: center;
    padding: 2em 5em;
    font-family: $font-title;
    @media screen and (max-width: 1600px) {
        padding: 2em 5em 2em; }
    @media screen and (max-width: 1024px) {
        padding: 1em 1em 0; }
    @media (min-width: 769px) and (max-width: 792px) {
        padding: 0; }
    @media screen and (max-width: 760px) {
        padding-top: 1em; }

    .img-div {
        @media screen and (max-width: 1020px) {
            padding: 0; } }
    .paragraph {
        text-align: justify;
        padding: 2em 5em 0 2em;
        @media screen and (min-width: 1600px) {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            padding: 3.5em 9em 0 3em; }
        @media screen and (max-width: 1023px) {
            padding: 2em 3em 0 2em; }
        @media screen and (max-width: 1006px) {
            padding: 2em 2em 0 2em; }
        @media screen and (max-width: 768px) {
            padding: 1em 1em 0 3em; }
        @media screen and (max-width: 767px) {
            padding: 1em 2em 0 0; }
        @media screen and (max-width: 641px) {
            padding: 1em 0 0 1rem; }
        @media screen and (max-width: 613px) {
            padding: 1em 0 0 .5em; }
        @media screen and (max-width: 582px) {
            padding: 1em 0 0 1em; }
        @media screen and (max-width: 547px) {
            padding: 1em 0.5em 1em 1em; }

        .title-header {
            font-size: 45px;
            @media screen and (min-width: 1600px) {
                margin-bottom: 0;
                letter-spacing: 2px; }
            @media screen and (max-width: 1024px) {
                font-size: 37px; }
            @media screen and (max-width: 1182px) {
                margin-bottom: 0; }
            @media (min-width: 769px) and (max-width: 792px) {
                font-size: 30px;
                margin-bottom: 0; }
            @media screen and (max-width: 768px) {
                font-size: 1.7rem;
                text-align: left;
                margin-bottom: 10px;
                line-height: 1.2; } }
        .desc {
            font-size: 18px;
            @media screen and (max-width: 1023px) {
                line-height: 1.5;
                font-size: 17px; }
            @media screen and (max-width: 890px) {
                font-size: 16px; }
            @media screen and (max-width: 800px) {
                font-size: 15px; }
            @media (min-width: 769px) and (max-width: 792px) {
                font-size: 14.5px;
                line-height: 1.4; }
            @media screen and (max-width: 768px) {
                font-size: 0.9rem; }
            @media screen and (max-width: 760px) {
                font-size: small;
                text-align: justify; }
            @media screen and (max-width: 547px) {
                font-size: 12px; } } }

    @media screen and (max-width: 540px) {
        padding: 0; }
    .tellwell-ratings {
        padding: 10px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (min-width: 769px) and (max-width: 792px) {
            padding: 8px; }
        @media screen and (max-width: 760px) {
            padding: 0 3px 0 0; }
        @media screen and (max-width: 600px) {
            right: 18px; }
        @media screen and (max-width: 445px) {
            right: 30px; }
        @media screen and (max-width: 400px) {
            right: 0; }

        h2 {
            font-weight: 100;
            margin: 0;
            @media screen and (min-width: 1600px) {
                font-size: 40px; }
            @media screen and (max-width: 1024px) {
                font-size: 28px; }
            @media screen and (max-width: 831px) {
                font-size: 25px; }
            @media screen and (max-width: 760px) {
                font-size: 17px; } }

        span {
            @media screen and (min-width: 1600px) {
                font-size: larger; }
            @media screen and (max-width: 831px) {
                font-size: 15px; }
            @media (min-width: 769px) and (max-width: 792px) {
                font-size: 15px; }
            @media screen and (max-width: 768px) {
                font-size: 15px; }
            @media screen and (max-width: 760px) {
                font-size: 13px; }
            @media screen and (max-width: 576px) {
                font-size: 12px; }
            @media screen and (max-width: 547px) {
                font-size: 11px; } }

        span.better-business, span.five-star {
            white-space: nowrap;
            @media screen and (max-width: 400px) {
                white-space: break-spaces; } } }

    .book-circle {
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
        aspect-ratio: 364 / 389;
        width: 100%;
        height: 100%;
        img {
            @media screen and (min-width: 1600px) {
                height: auto; }
            @media screen and (max-width: 1120px) {
                position: absolute;
                max-width: 265px; }
            @media screen and (max-width: 1023px) {
                max-width: 260px; }
            @media screen and (max-width: 980px) {
                max-width: 250px;
                top: 25px; }
            @media screen and (max-width: 925px) {
                max-width: 230px;
                top: 25px;
                left: -7px; }
            @media screen and (max-width: 833px) {
                max-width: 225px;
                left: -12px; }
            @media screen and (max-width: 768px) {
                max-width: 210px;
                left: 0;
                top: 0; }
            @media screen and (max-width: 700px) {
                top: 10px;
                max-width: 200px; }
            @media screen and (max-width: 622px) {
                position: relative;
                top: -10px;
                max-width: 190px; }
            @media screen and (max-width: 541px) {
                max-width: 100%;
                top: -10px;
                left: 0; } } } }


// Higher than 1920px
@media screen and (min-width: 1921px) {
    #tellwell-rating-wrapper {
        padding: 2em 4em;
        .div-wrapper {
            padding: 0 2em 0;

            .paragraph {
                padding: 5em 10em 0 3em;

                .title-header {
                    font-size: 45px; } } }
        .img-div {
            display: flex;
            align-items: center;
            img {
                height: auto;
                width: 95%; } } } }
// Large screen 90% zoom out in desktop
@media (min-width: 1540px) and (max-width: 1919px) {
    #tellwell-rating-wrapper {
        .title-header {
            font-size: 44px; }

        .paragraph {
            .desc {
                line-height: 1.5;
                padding-bottom: 1em; } }
        .tellwell-ratings {
            h2 {
                font-size: 34px; } } } }

// Small Laptops
@media (min-width: 1024px) and (max-width: 1279px) {
    .carousel-authors {
        .img-container {
            max-height: 100%;
            max-width: 100%;
            img {
                height: auto;
                display: block; } } }
    #tellwell-rating-wrapper {
        padding: 0 1em;

        .paragraph {
            padding: 2em 2em 0;
            .desc {
                font-size: 16px; } }
        .title-header {
            font-size: 35px; }

        .tellwell-ratings {
            h2 {
                font-size: 26px; } }
        .book-circle {
            height: 100%;
            align-items: center; }

        .tellwell-ratings {
            padding: 10px 0 20px; } } }

// @media (min-width: 992px) and (max-width: 1024px)
//     .book-circle
//         height: 300px

//     .paragraph
//         padding: 2em 2em 0

//     .tellwell-ratings
//         padding: 10px 0 20px

// Large tablets (landscape)
@media (min-width: 992px) and (max-width: 1024px) {
    .book-circle {
        height: 80%;
        align-items: center; }

    .paragraph {
        padding: 1em 2em; }

    .tellwell-ratings {
        padding: 10px 0 20px; }

    .carousel-authors {
        height: auto; } }


//  Small TABLET (portrait)
@media (min-width: 768px) and (max-width: 993px) {
    .book-circle {
        align-items: center !important;
        height: 80%; }
    .title-header {
        font-size: 30px;

        .desc {
            font-size: 15.5px; } }

    .tellwell-ratings {
        padding: 10px 0 20px;
        h2 {
          font-size: 23px; } } }

// Large mobile (phablets)
@media (min-width: 576px) and (max-width: 767px) {
    .book-circle {
        height: 70%; }

    .tellwell-ratings {
        padding: 10px 0 20px;
        h2 {
          font-size: 20px; } } }

// Small mobile
@media screen and (max-width: 591px) {
    .img-div {
        padding: 0;
        left: 3px; }
    .book-circle {
        height: 60%; }
    .title-header {
        font-size: 20px !important;
        line-height: 25px;

        .desc {
            line-height: 20px; } }

    .tellwell-ratings {
        padding: 0;
        line-height: 15px;
        h2 {
          font-size: 17px; } } }

// Extra small mobile (portrait phones)
@media (max-width: 374px) {
    .img-div {
        left: 13px; }
    .paragraph {
        padding: .5em 1.5em 1em 1em !important;

        h2 {
            font-size: 15px; }
        span {
            font-size: 11px; } } }

.tellwell-publishing-home-content,.home-testimonials {
    h1, h2, h3, h4, h5, h6 {
        font-family: $font-title; } //$font-header-main
    p {
        font-family: $font-body; } }

.service-intro-text {
    h1, h6 {
        text-align: center; }
    h4 {
        text-align: center;
        color: $brightblue-color; }
    p {
        @media screen and (max-width: 540px) {
            padding: 0; }
        text-align: center;
        color: #7E7E7E;
        font-size: $mediumfont-size;
        padding: 0 100px 0 100px; }

    h6 {
        line-height: 2.5em;
        color: $brightblue-color;
        letter-spacing: 0.1em; } }

.homepage-services-card {
    // TODO: right edge has a border but not the left; looks great on mobile but on desktop looks slightly uneven when left edge in first column doesn't have a line; barely noticeable but would be nice to adjust? (low prio)
    border-right-style: inset;
    padding: 20px;
    margin-bottom: 15px;
    margin-top: 15px;
    i {
        color: $brightblue-color; }
    .service-title {
        padding-left: 15px;
        padding-right: 15px; }
    .service-description {
        font-size: 16px;
        font-weight: 400;
        color: #696969;

        p {
            font-size: $defaultfont-size; }

        .service-footer {
            color: $brightblue-color;
            font-weight: bold;
            position: absolute;
            bottom: 0; } } }

.get-free-guide {
    background-color: #DAFBFF; }
.why-tellwell {
    background-color: #DAFBFF;
    .why-tellwell-cards {
        .card-block {
            background-color: $brightblue-color;
            height: 250px;
            @media screen and (max-width: 540px) {
                height: auto; }
            @media screen and (min-width: 768px) and (max-width: 1024px) {
                height: 330px; }
            margin: 10px;
            padding: 30px 10px 30px 10px;
            .wrapper {
                display: table;
                width: 70px;
                height: 70px;
                color: white;
                border-style: solid;
                border-radius: 999px;
                font-size: 1.3rem;
                i {
                    display: table-cell;
                    vertical-align: middle;
                    text-align: center;
                    color: white; } }
            .card-title {
                color: #FFFFFF; }
            .card-text {
                color: #FFFFFFD1;
                font-size: $mediumfont-size;
                font-weight: 400; } } } }

.self-pub-vs-traditional {
    font-family: Nunito Sans;
    h5 {
        color: $brightblue-color;
        letter-spacing: 0.3em; }
    ul li {
        font-size: $mediumfont-size; }
    .img-section {
        position: relative;
        width: 100%;
        padding-top: 39%;
        overflow: hidden;
        display: flex;
        @media screen and (max-width: 600px) {
            padding-top: 65%; }
        @media screen and (max-width: 376px) {
            padding-top: 75%; }
        img {
            position: absolute;
            top: 0;
            width: 580px;
            height: auto;
            @media only screen and (max-width: 1024px) and (min-width: 940px) {
                width: 460px; }
            @media screen and (max-width: 800px) {
                margin-top: 0;
                width: 350px; }
            @media screen and (max-width: 320px) {
                width: 300px; } } } }



#divider-latest-news {
    background-color: black;
    text-align: center;
    font-family: $font-body;
    p {
        color: white;
        padding-top: 4px; } }

// Author Business services
.carousel-business-logos {
    .card {
        background-color: white;
        height: 200px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        padding: 2em;
        margin: 10px; } }

.home-custom-btns {
    z-index: 100;
    margin: 0;
    position: absolute;
    top: 90%;
    @media screen and (min-width: 1200px) {
        top: 95vh; }
    @media screen and (max-width: 1024px) and (max-width: 1200px) {
        top: 100vh; }
    @media screen and (min-width: 820px) and (max-width: 960px) {
        top: 60vh; }
    @media screen and (min-width: 768px) and (max-width: 800px) {
        top: 107vh; }
    @media screen and (min-width: 420px) and (max-width: 760px) {
        top: 120vh; }
    @media screen and (max-width: 400px) {
        top: 95vh; }
    @media screen and (max-width: 376px) {
        top: 123vh; }
    transform: translateY(-50%);

    .home-slick-btns {
        .custom-slick-btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 0 8px;
            width: 48px;
            height: 48px;
            border-radius: 48px;
            color: #ffffff;
            box-shadow: none;
            background: transparent;
            font-size: .875rem;
            line-height: 1;
            border: 0 solid transparent;
            cursor: pointer;
            i {
                font-size: 30px; } } } }

// Author Business services
.carousel-business-logos {
    .card {
        background-color: white;
        height: 200px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        padding: 2em;
        margin: 10px; } }

#home-our-books {
    h1 {
        font-weight: 400;
        padding-top: 1em; }

    .our-books-home-slider {
        .regular-books {
            padding: 10px;
            @media screen and (min-width: 1540px) {
                padding: 0; }
            .card {
                padding: 10px;
                width: auto;
                height: auto;
                img {
                    height: 430px;
                    max-height: 430px;
                    width: auto;

                    @media screen and (max-width: 1366px) {
                        height: auto;
                        max-height: 350px;
                        width: auto; }
                    @media screen and (max-width: 1296px) {
                        max-height: 347px; }
                    @media screen and (max-width: 1290px) {
                        height: auto;
                        max-height: 390px;
                        width: 100%; }
                    @media screen and (max-width: 1290px) {
                        max-height: 335px; }
                    @media screen and (max-width: 1250px) {
                        max-height: 324px; }
                    @media screen and (max-width: 1220px) {
                        max-height: 380px; }
                    @media screen and (max-width: 1219px) {
                        max-height: 414px; } /// displays 4 images in carousel
                    @media screen and (max-width: 1217px) {
                        max-height: 385px; }
                    @media screen and (max-width: 1134px) {
                        max-height: 375px; }
                    @media screen and (max-width: 1104px) {
                        max-height: 365px; }
                    @media screen and (max-width: 1083px) {
                        max-height: 355px; }
                    @media screen and (max-width: 1057px) {
                        max-height: 345px; }
                    @media screen and (max-width: 1040px) {
                        max-height: 325px; }
                    @media screen and (max-width: 978px) {
                        max-height: 315px; }
                    @media screen and (max-width: 949px) {
                        max-height: 300px;
                        height: 300px; }
                    @media screen and (max-width: 928px) {
                        max-height: 400px;
                        height: auto; }
                    @media screen and (max-width: 891px) {
                        max-height: 380px; }
                    @media screen and (max-width: 850px) {
                        max-height: 370px; }
                    @media screen and (max-width: 830px) {
                        max-height: 360px; }
                    @media screen and (max-width: 810px) {
                        max-height: 345px; }
                    @media screen and (max-width: 778px) {
                        max-height: 335px; }
                    @media screen and (max-width: 768px) {
                        max-height: 330px; }
                    @media screen and (max-width: 748px) {
                        max-height: 315px; }
                    @media screen and (max-width: 720px) {
                        max-height: 300px; }
                    @media screen and (max-width: 690px) {
                        max-height: 370px;
                        margin: 0 auto;
                        width: auto; } } } }

        .childrens-books {
            padding: 10px;
            // margin-right: auto
            // margin-left: auto
            // max-width: 1460px
            // .slick-list
            //     overflow: visible
            .card {
                padding: 8px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    max-height: 355px;
                    height: 355px;
                    width: auto;
                    @media screen and (max-width: 1366px) {
                        height: 255px;
                        max-height: 255px;
                        width: auto;
                        padding: 0 0 1em 0; }
                    @media screen and (max-width: 1220px) {
                        height: 298px;
                        max-height: 280px; }
                    @media screen and (max-width: 1100px) {
                        height: 256px;
                        max-height: 100%; }
                    @media screen and (max-width: 670px) {
                        height: 300px; } } } }

        .carousel-books {
            opacity: 0;
            visibility: hidden;
            transition: opacity 1s ease;
            -webkit-transition: opacity 1s ease;
            display: none; }

        .carousel-books.slick-initialized {
            visibility: visible;
            opacity: 1;
            display: block; } } }

.btn-quote {
    background-color: $brightblue-color;
    color: white; }

#home-services-wrapper {
    background-color: $mutedblue-color;

    h1,h5 {
        font-weight: 100;
        align-items: center;
        display: flex;
        justify-content: center; }

    .image-map-container {
        position: relative;
        overflow: hidden;
        vertical-align: bottom;

        @media screen and (max-width: 956px) {
            padding: 0; }

        .table-row1,.table-row2 {
            .contents {
                position: relative;
                color: white;
                @media only screen and (max-width: 900px) {
                    font-size: 14px; } }

            th,td {
                border: 1px solid transparent; } }

        .services-circle {
            display: inline-block;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background-color: #3498db; }

        .table-row1 {
            margin: 6em 0 0;
            td {
                text-align: center;
                align-content: end;
                padding: 0; }

            .firstrow-col1 {
                width: 75px;
                @media only screen and (max-width: 1367px) {
                    width: 70px; }
                @media only screen and (max-width: 1200px) {
                    width: 65px; }
                @media only screen and (max-width: 1082px) {
                    width: 70px; }
                @media only screen and (max-width: 926px) {
                    width: 65px; } }

            .firstrow-col9 {
                width: 75px;
                @media only screen and (max-width: 1367px) {
                    width: 70px; }
                @media only screen and (max-width: 1200px) {
                    width: 65px; } }

            .firstrow-col5, .firstrow-col3 ,.firstrow-col7 {
                width: 3px;
                @media only screen and (max-width: 1367px) {
                    width: 1px; } }

            .ghostwriting-col {
                position: relative;
                width: 90px;
                @media only screen and (max-width: 1311px) {
                    width: 110px; }
                @media only screen and (max-width: 1281px) {
                    width: 122px; }
                @media only screen and (max-width: 1200px) {
                    width: 125px; }
                @media only screen and (max-width: 900px) {
                    width: 135px; } }

            .illustration-col {
                position: relative;
                width: 90px;
                @media only screen and (max-width: 1200px) {
                    width: 96px; }
                @media only screen and (max-width: 926px) {
                    width: 90px; } }

            .distribution-col {
                position: relative;
                width: 90px;
                @media only screen and (max-width: 1281px) {
                    width: 100px; }
                @media only screen and (max-width: 1080px) {
                    width: 106px;
                    padding-top: 8px; }
                @media only screen and (max-width: 980px) {
                    width: 120px; }
                @media only screen and (max-width: 900px) {
                    padding-top: 0; } }

            .audiobook-col {
                position: relative;
                width: 90px;
                @media only screen and (max-width: 1441px) {
                    width: 100px; }
                @media only screen and (max-width: 1367px) {
                    width: 103px; }
                @media only screen and (max-width: 1311px) {
                    width: 125px; }
                @media only screen and (max-width: 1200px) {
                    width: 127px; }
                @media only screen and (max-width: 1152px) {
                    padding-top: 8px; }
                @media only screen and (max-width: 926px) {
                    width: 130px; }
                @media only screen and (max-width: 900px) {
                    padding-top: 0; } } }


        .table-row2 {
            margin-bottom: 7em;
            @media only screen and (max-width: 982px) {
                margin-bottom: 8em; }
            td {
                width: 40px;
                text-align: center;
                align-content: end;
                padding: 0; }

            .book-coaching-col, .marketing-col, .printing-col, .editing-col, .design-col {
                position: relative;
                width: 120px;
                @media only screen and (max-width: 900px) {
                    width: 114px; } }

            .secondrow-col1 {
                width: 50px;
                @media only screen and (max-width: 1082px) {
                    width: 30px; } }

            .secondrow-col3, .secondrow-col5, .secondrow-col7, .secondrow-col9 {
                width: 15px;
                @media only screen and (max-width: 1082px) {
                    width: 1px; } }

            .secondrow-col9 {
                @media only screen and (max-width: 1367px) {
                    width: 5px; }
                @media only screen and (max-width: 900px) {
                    width: 1px; } }

            .secondrow-col10 {
                @media only screen and (max-width: 1082px) {
                    width: 20px; } }

            .marketing-col {
                .contents {
                    left: -25px; } }

            .printing-col {
                @media only screen and (max-width: 1367px) {
                    width: 138px; }
                @media only screen and (max-width: 900px) {
                    width: 130px; }
                .contents {
                    left: -17px; } } }

        .hidden-content.show-hovered {
            display: block !important;
            bottom: 0;
            opacity: 99 !important; }

        .hidden-content {
            display: none;
            position: absolute;
            opacity: 0;
            transform: translateY(100%);
            transition: all 0.5s ease;
            font-size: smaller;
            background-color: transparent;
            border-radius: 5px;
            padding: 0 3px 0;
            line-height: 1.4;

            p {
                color: #ffffff;
                @media only screen and (max-width: 1200px) {
                    font-size: 14px;
                    font-weight: 500; }
                @media only screen and (max-width: 1082px) {
                    font-size: 13.5px; }
                @media only screen and (max-width: 900px) {
                    font-size: 12.5px; }
                @media only screen and (max-width: 840px) {
                    line-height: 1.2; } } }

        .hidden-content.animate-up {
            display: block;
            opacity: 99;
            transform: translateY(0);
            transition: top 0.3s ease, opacity 0.3s ease; }

        .hidden-content.animate-down {
            display: block;
            opacity: 99;
            transform: translateY(50);
            transition: top 0.4s ease, opacity 0.4s ease;
 }            // bottom: 50px

        .display-mobile {
            .contents {
                a {
                    color: white; } }
            table {
                td {
                    border: 1px solid transparent; }
                .row1 {
                    @media screen and (max-width: 860px) {
                        height: 178px; }
                    @media screen and (max-width: 800px) {
                        height: 147px; }
                    @media screen and (max-width: 768px) {
                        height: 150px; }
                    @media screen and (max-width: 736px) {
                        height: 150px; }
                    @media screen and (max-width: 678px) {
                        height: 156px; }
                    @media screen and (max-width: 589px) {
                        height: 162px; }
                    @media screen and (max-width: 586px) {
                        height: 145px; }
                    @media screen and (max-width: 583px) {
                        height: 138px; }
                    @media screen and (max-width: 579px) {
                        height: 139px; }
                    @media screen and (max-width: 530px) {
                        height: 140px; }
                    @media screen and (max-width: 512px) {
                        height: 127px; }
                    @media screen and (max-width: 506px) {
                        height: 120px; }
                    @media screen and (max-width: 460px) {
                        height: 80px; }

                    .row1col1 {
                        @media screen and (max-width: 768px) {
                            width: 97px; }
                        @media screen and (max-width: 758px) {
                            width: 102px; }
                        @media screen and (max-width: 736px) {
                            width: 108px; }
                        @media screen and (max-width: 711px) {
                            width: 116px; }
                        @media screen and (max-width: 708px) {
                            width: 125px; }
                        @media screen and (max-width: 701px) {
                            width: 105px; }
                        @media screen and (max-width: 681px) {
                            width: 100px; }
                        @media screen and (max-width: 662px) {
                            width: 120px; }
                        @media screen and (max-width: 651px) {
                            width: 115px; }
                        @media screen and (max-width: 647px) {
                            width: 117px; }
                        @media screen and (max-width: 638px) {
                            width: 98px; }
                        @media screen and (max-width: 589px) {
                            width: 100px; }
                        @media screen and (max-width: 586px) {
                            width: 70px; }
                        @media screen and (max-width: 568px) {
                            width: 90px; }
                        // @media screen and (max-width: 545px)
                        //     width: 89px
                        @media screen and (max-width: 440px) {
                            width: 86px; }
                        @media screen and (max-width: 426px) {
                            width: 5px; }
                        @media screen and (max-width: 376px) {
                            width: 75px; } }
                    .row1col3 {
                        @media screen and (max-width: 768px) {
                            width: 50px; }
                        @media screen and (max-width: 758px) {
                            width: 30px; }
                        @media screen and (max-width: 589px) {
                            width: 111px; }
                        @media screen and (max-width: 586px) {
                            width: 80px; }
                        @media screen and (max-width: 568px) {
                            width: 100px; }
                        @media screen and (max-width: 440px) {
                            width: 70px; }
                        @media screen and (max-width: 426px) {
                            width: 5px; }
                        @media screen and (max-width: 376px) {
                            width: 75px; } }

                    .mobile-services-img {
                        @media screen and (max-width: 787px) {
                            width: 305px; }
                        @media screen and (max-width: 768px) {
                            width: 150px; }
                        @media screen and (max-width: 758px) {
                            width: 154px; }
                        @media screen and (max-width: 745px) {
                            width: 155px; }
                        @media screen and (max-width: 736px) {
                            width: 160px; }
                        @media screen and (max-width: 711px) {
                            width: 166px; }
                        @media screen and (max-width: 708px) {
                            width: 185px; }
                        @media screen and (max-width: 701px) {
                            width: 154px; }
                        @media screen and (max-width: 700px) {
                            width: 157px; }
                        @media screen and (max-width: 687px) {
                            width: 158px; }
                        @media screen and (max-width: 681px) {
                            width: 150px; }
                        @media screen and (max-width: 680px) {
                            width: 161px; }
                        @media screen and (max-width: 675px) {
                            width: 163px; }
                        @media screen and (max-width: 674px) {
                            width: 159px; }
                        @media screen and (max-width: 668px) {
                            width: 164px; }
                        @media screen and (max-width: 667px) {
                            width: 157px; }
                        @media screen and (max-width: 662px) {
                            width: 177px; }
                        @media screen and (max-width: 657px) {
                            width: 180px; }
                        @media screen and (max-width: 651px) {
                            width: 178px; }
                        @media screen and (max-width: 647px) {
                            width: 182px; }
                        @media screen and (max-width: 641px) {
                            width: 185px; }
                        @media screen and (max-width: 629px) {
                            width: 192px; }
                        @media screen and (max-width: 623px) {
                            width: 195px; }
                        @media screen and (max-width: 618px) {
                            width: 201px; }
                        @media screen and (max-width: 605px) {
                            width: 209px; }
                        @media screen and (max-width: 599px) {
                            width: 218px; }
                        @media screen and (max-width: 592px) {
                            width: 221px; }
                        @media screen and (max-width: 589px) {
                            width: 230px; }
                        @media screen and (max-width: 586px) {
                            width: 200px; }
                        @media screen and (max-width: 584px) {
                            width: 198px; }
                        @media screen and (max-width: 570px) {
                            width: 211px; }
                        @media screen and (max-width: 568px) {
                            width: 225px; }
                        @media screen and (max-width: 558px) {
                            width: 210px; }
                        @media screen and (max-width: 545px) {
                            width: 158px; }
                        @media screen and (max-width: 528px) {
                            width: 168px; }
                        @media screen and (max-width: 520px) {
                            width: 168px; }
                        @media screen and (max-width: 512px) {
                            width: 186px; }
                        @media screen and (max-width: 508px) {
                            width: 155px; }
                        @media screen and (max-width: 440px) {
                            width: 193px; }
                        @media screen and (max-width: 432px) {
                            width: 185px; }
                        @media screen and (max-width: 426px) {
                            width: 52px; }
                        @media screen and (max-width: 415px) {
                            width: 62px; }
                        @media screen and (max-width: 400px) {
                            width: 64px; }
                        @media screen and (max-width: 376px) {
                            width: 160px;
                            padding: 0; }
                        @media screen and (max-width: 361px) {
                            width: 176px; } } }

                .row2,.row4, .row6, .row8, .row10 {
                    @media screen and (max-width: 860px) {
                        height: 102px; }
                    @media screen and (max-width: 800px) {
                        height: 103px; }
                    @media screen and (max-width: 768px) {
                        height: 77px; }
                    @media screen and (max-width: 746px) {
                        height: 73px; }
                    @media screen and (max-width: 744px) {
                        height: 65px; }
                    @media screen and (max-width: 680px) {
                        height: 75px; }
                    @media screen and (max-width: 586px) {
                        height: 81px; }
                    @media screen and (max-width: 501px) {
                        height: 80px; }
                    @media screen and (max-width: 426px) {
                        height: 60px; }
                    @media screen and (max-width: 376px) {
                        height: 55px; }
                    @media screen and (max-width: 321px) {
                        height: 30px; } }

                .row3, .row5, .row7, .row9 {
                    @media screen and (max-width: 860px) {
                        height: 110px; }
                    @media screen and (max-width: 800px) {
                        height: 85px; }
                    @media screen and (max-width: 746px) {
                        height: 103px; }
                    @media screen and (max-width: 744px) {
                        height: 90px; }
                    @media screen and (max-width: 743px) {
                        height: 83px; }
                    @media screen and (max-width: 736px) {
                        height: 84px; }
                    @media screen and (max-width: 701px) {
                        height: 90px; }
                    @media screen and (max-width: 586px) {
                        height: 70px; }
                    @media screen and (max-width: 426px) {
                        height: 55px; } }


                .row2,.row4,.row6, .row8, .row10 {
                    @media screen and (max-width: 860px) {
                        td {
                            align-content: center; }

                        .contents {
                            position: relative;
                            float: right;
                            align-content: end;
                            left: 100px; } }
                    @media screen and (max-width: 795px) {
                        .contents {
                            left: 83px; } }
                    @media screen and (max-width: 768px) {
                        .contents {
                            left: 88px; } }
                    @media screen and (max-width: 743px) {
                        .contents {
                            left: 82px; } }
                    @media screen and (max-width: 736px) {
                        .contents {
                            left: 85px; } }
                    @media screen and (max-width: 440px) {
                        .contents {
                            font-size: 14px; } }
                    @media screen and (max-width: 426px) {
                        .contents {
                            //position: absolute
                            left: 45px;
                            font-size: 15px; } }
                    @media screen and (max-width: 415px) {
                        .contents {
                            font-size: small; } }
                    @media screen and (max-width: 376px) {
                        td {
                            padding: 0; } } }

                .row2 {
                    .contents {
                        @media screen and (max-width: 795px) {
                            top: 5px; }
                        @media screen and (max-width: 768px) {
                            position: absolute;
                            top: 195px;
                            left: 136px; }
                        @media screen and (max-width: 748px) {
                            top: 176px;
                            left: 145px; }
                        @media screen and (max-width: 745px) {
                            top: 177px; }
                        @media screen and (max-width: 743px) {
                            left: 144px; }
                        @media screen and (max-width: 720px) {
                            left: 140px;
                            top: 171px; }
                        @media screen and (max-width: 716px) {
                            top: 171px; }
                        @media screen and (max-width: 707px) {
                            top: 174px;
                            left: 145px; }
                        @media screen and (max-width: 686px) {
                            left: 140px; }
                        @media screen and (max-width: 680px) {
                            left: 129px;
                            top: 176px; }
                        @media screen and (max-width: 678px) {
                            left: 131px; }
                        @media screen and (max-width: 667px) {
                            left: 129px;
                            top: 170px; }
                        @media screen and (max-width: 665px) {
                            left: 123px; }
                        @media screen and (max-width: 641px) {
                            top: 170px;
                            left: 126px; }
                        @media screen and (max-width: 628px) {
                            top: 178px;
                            left: 103px; }
                        @media screen and (max-width: 626px) {
                            top: 178px;
                            left: 102px; }
                        @media screen and (max-width: 609px) {
                            top: 176px;
                            left: 93px; }
                        @media screen and (max-width: 605px) {
                            left: 91px; }
                        @media screen and (max-width: 600px) {
                            left: 89px; }
                        @media screen and (max-width: 591px) {
                            left: 84px; }
                        @media screen and (max-width: 588px) {
                            left: 73px; }
                        @media screen and (max-width: 583px) {
                            left: 70px;
                            top: 173px; }
                        @media screen and (max-width: 581px) {
                            left: 85px; }
                        @media screen and (max-width: 579px) {
                            left: 70px;
                            top: 173px; }
                        @media screen and (max-width: 572px) {
                            left: 68px;
                            top: 169px; }
                        @media screen and (max-width: 558px) {
                            left: 67px;
                            top: 155px; }
                        @media screen and (max-width: 557px) {
                            left: 63px;
                            top: 161px; }
                        @media screen and (max-width: 545px) {
                            top: 163px; }
                        @media screen and (max-width: 544px) {
                            top: 145px; }
                        @media screen and (max-width: 540px) {
                            top: 142px;
                            left: 52px; }
                        @media screen and (max-width: 531px) {
                            top: 146px; }
                        @media screen and (max-width: 512px) {
                            top: 135px;
                            left: 53px; }
                        @media screen and (max-width: 501px) {
                            top: 129px;
                            left: 50px; }
                        @media screen and (max-width: 440px) {
                            top: 141px;
                            position: absolute !important;
                            left: 52px; }
                        @media screen and (max-width: 431px) {
                            top: 144px;
                            left: 49px; }
                        @media screen and (max-width: 426px) {
                            top: 131px;
                            left: 36px; }
                        @media screen and (max-width: 415px) {
                            top: 140px;
                            left: 38px; }
                        @media screen and (max-width: 391px) {
                            top: 133px;
                            left: 38px; }
                        @media screen and (max-width: 376px) {
                            top: 115px;
                            left: 18px; }
                        @media screen and (max-width: 361px) {
                            top: 115px;
                            left: 11px; }
                        @media screen and (max-width: 345px) {
                            top: 114px;
                            left: 17px; }
                        @media screen and (max-width: 321px) {
                            top: 104px;
                            left: 10px; } } }
                .row3 {
                    .contents {
                        @media screen and (max-width: 795px) {
                            top: 5px; }
                        @media screen and (max-width: 768px) {
                            top: -10px; }
                        @media screen and (max-width: 745px) {
                            top: -13px; }
                        @media screen and (max-width: 545px) {
                            top: -29px;
                            right: 78px; }
                        @media screen and (max-width: 544px) {
                            top: -28px; }
                        @media screen and (max-width: 491px) {
                            right: 56px; }
                        @media screen and (max-width: 440px) {
                            right: 56px;
                            position: absolute !important;
                            top: 212px; }
                        @media screen and (max-width: 431px) {
                            right: 54px !important;
                            top: 213px; }
                        @media screen and (max-width: 426px) {
                            top: 209px !important;
                            right: 64px !important; }
                        @media screen and (max-width: 415px) {
                            top: 195px; }
                        @media screen and (max-width: 413px) {
                            top: 199px;
                            right: 66px; }
                        @media screen and (max-width: 376px) {
                            top: 178px !important;
                            right: 44px !important; }
                        @media screen and (max-width: 361px) {
                            right: 36px !important; }
                        @media screen and (max-width: 345px) {
                            top: 177px;
                            right: 33px !important; }
                        @media screen and (max-width: 321px) {
                            top: 162px;
                            right: 24px !important; } } }
                .row4 {
                    .contents {
                        @media screen and (max-width: 800px) {
                            top: -13px; }
                        @media screen and (max-width: 795px) {
                            top: 0; }
                        @media screen and (max-width: 768px) {
                            top: -9px; }
                        @media screen and (max-width: 748px) {
                            top: -12px; }
                        @media screen and (max-width: 745px) {
                            top: -16px; }
                        @media screen and (max-width: 743px) {
                            top: -14px; }
                        @media screen and (max-width: 736px) {
                            top: -20px; }
                        @media screen and (max-width: 701px) {
                            top: -14px; }
                        @media screen and (max-width: 680px) {
                            top: -11px; }
                        @media screen and (max-width: 667px) {
                            top: -15px; }
                        @media screen and (max-width: 545px) {
                            top: -33px;
                            left: 76px; }
                        @media screen and (max-width: 508px) {
                            top: -45px;
                            left: 71px; }
                        @media screen and (max-width: 440px) {
                            position: absolute !important;
                            top: 278px;
                            left: 98px; }
                        @media screen and (max-width: 431px) {
                            left: 100px;
                            top: 281px; }
                        @media screen and (max-width: 426px) {
                            top: 260px;
                            left: 86px; }
                        @media screen and (max-width: 415px) {
                            left: 87px;
                            top: 275px; }
                        @media screen and (max-width: 391px) {
                            top: 261px;
                            left: 79px; }
                        @media screen and (max-width: 376px) {
                            left: 67px !important;
                            top: 236px; }
                        @media screen and (max-width: 361px) {
                            left: 60px !important; }
                        @media screen and (max-width: 345px) {
                            top: 234px;
                            left: 60px !important; }
                        @media screen and (max-width: 321px) {
                            top: 218px;
                            left: 51px !important; } } }
                .row5 {
                    .contents {
                        @media screen and (max-width: 800px) {
                            top: -2px; }
                        @media screen and (max-width: 795px) {
                            top: 1px; }
                        @media screen and (max-width: 768px) {
                            top: -13px; }
                        @media screen and (max-width: 545px) {
                            top: -38px;
                            right: 38px; }
                        @media screen and (max-width: 508px) {
                            top: -53px; }
                        @media screen and (max-width: 440px) {
                            position: absolute !important;
                            top: 349px; }
                        @media screen and (max-width: 431px) {
                            top: 351px; }
                        @media screen and (max-width: 426px) {
                            top: 324px;
 }                            //right: 86px
                        @media screen and (max-width: 413px) {
                            top: 343px; }
                        @media screen and (max-width: 376px) {
                            top: 297px; }
                        @media screen and (max-width: 345px) {
                            top: 299px; }
                        @media screen and (max-width: 321px) {
                            top: 274px; } } }

                .row6 {
                    .contents {
                        @media screen and (max-width: 800px) {
                            top: 3px; }
                        @media screen and (max-width: 795px) {
                            top: -3px; }
                        @media screen and (max-width: 768px) {
                            top: -12px; }
                        @media screen and (max-width: 736px) {
                            top: -18px; }
                        @media screen and (max-width: 641px) {
                            top: 527px;
                            left: 103px;
                            position: absolute !important; }
                        @media screen and (max-width: 638px) {
                            top: 552px;
                            left: 83px; }
                        @media screen and (max-width: 624px) {
                            left: 84px; }
                        @media screen and (max-width: 608px) {
                            left: 70px;
                            top: 550px; }
                        @media screen and (max-width: 605px) {
                            top: 558px;
                            left: 66px; }
                        @media screen and (max-width: 591px) {
                            left: 68px; }
                        @media screen and (max-width: 589px) {
                            top: 544px;
                            left: 52px; }
                        @media screen and (max-width: 583px) {
                            top: 544px;
                            left: 47px; }
                        @media screen and (max-width: 581px) {
                            left: 43px;
                            top: 540px; }
                        @media screen and (max-width: 579px) {
                            left: 40px;
                            top: 529px; }
                        @media screen and (max-width: 558px) {
                            left: 39px;
                            top: 513px; }
                        @media screen and (max-width: 545px) {
                            top: 426px;
                            left: 43px; }
                        @media screen and (max-width: 543px) {
                            top: 423px; }
                        @media screen and (max-width: 530px) {
                            top: 429px;
                            left: 36px; }
                        @media screen and (max-width: 512px) {
                            top: 438px;
                            left: 25px; }
                        @media screen and (max-width: 508px) {
                            top: 389px;
                            left: 33px; }
                        @media screen and (max-width: 500px) {
                            top: 382px;
                            left: 29px; }
                        @media screen and (max-width: 440px) {
                            top: 416px;
                            left: 39px; }
                        @media screen and (max-width: 431px) {
                            left: 39px;
                            top: 418px; }
                        @media screen and (max-width: 426px) {
                            top: 387px;
                            left: 27px;
                            position: absolute !important; }
                        @media screen and (max-width: 415px) {
                            top: 409px;
                            left: 31px; }
                        @media screen and (max-width: 391px) {
                            left: 25px; }
                        @media screen and (max-width: 376px) {
                            top: 358px !important;
                            left: 13px !important; }
                        @media screen and (max-width: 361px) {
                            left: 5px !important; }
                        @media screen and (max-width: 345px) {
                            top: 358px !important;
                            left: 6px !important; }
                        @media screen and (max-width: 321px) {
                            top: 330px !important;
                            left: 1px !important; } } }
                .row7 {
                    .contents {
                        @media screen and (max-width: 800px) {
                            top: 9px; }
                        @media screen and (max-width: 795px) {
                            top: -4px; }
                        @media screen and (max-width: 768px) {
                            top: -11px; }
                        @media screen and (max-width: 545px) {
                            top: -76px; }
                        @media screen and (max-width: 544px) {
                            top: -48px;
                            right: 70px; }
                        @media screen and (max-width: 512px) {
                            top: -55px; }
                        @media screen and (max-width: 508px) {
                            top: -75px; }
                        @media screen and (max-width: 440px) {
                            position: absolute !important;
                            top: 487px;
                            right: 64px; }
                        @media screen and (max-width: 431px) {
                            top: 488px; }
                        @media screen and (max-width: 426px) {
                            top: 451px; }
                        @media screen and (max-width: 415px) {
                            top: 452px; }
                        @media screen and (max-width: 413px) {
                            top: 477px; }
                        @media screen and (max-width: 376px) {
                            top: 419px; }
                        @media screen and (max-width: 345px) {
                            top: 421px; }
                        @media screen and (max-width: 321px) {
                            top: 388px; } } }
                .row8 {
                    .contents {
                        @media screen and (max-width: 800px) {
                            top: 12px; }
                        @media screen and (max-width: 795px) {
                            top: -9px; }
                        @media screen and (max-width: 768px) {
                            top: -13px; }
                        @media screen and (max-width: 700px) {
                            top: -19px; }
                        @media screen and (max-width: 545px) {
                            top: -87px;
                            left: 76px; }
                        @media screen and (max-width: 544px) {
                            top: -53px; }
                        @media screen and (max-width: 530px) {
                            top: -65px; }
                        @media screen and (max-width: 512px) {
                            left: 73px; }
                        @media screen and (max-width: 508px) {
                            top: -89px;
                            left: 70px; }
                        @media screen and (max-width: 440px) {
                            top: 553px;
                            left: 95px;
                            position: absolute !important; }
                        @media screen and (max-width: 431px) {
                            left: 94px;
                            top: 554px; }
                        @media screen and (max-width: 426px) {
                            top: 512px;
                            left: 79px; }
                        @media screen and (max-width: 413px) {
                            top: 542px;
                            left: 83px; }
                        @media screen and (max-width: 376px) {
                            top: 479px;
                            left: 63px; }
                        @media screen and (max-width: 361px) {
                            left: 55px; }
                        @media screen and (max-width: 345px) {
                            top: 478px;
                            left: 55px; }
                        @media screen and (max-width: 321px) {
                            top: 443px;
                            left: 46px !important; } } }
                .row9 {
                    .contents {
                        @media screen and (max-width: 800px) {
                            top: 19px; }
                        @media screen and (max-width: 795px) {
                            top: -9px; }
                        @media screen and (max-width: 768px) {
                            top: -14px; }
                        @media screen and (max-width: 545px) {
                            top: -92px;
                            right: 73px; }
                        @media screen and (max-width: 544px) {
                            top: -53px;
                            right: 70px; }
                        @media screen and (max-width: 530px) {
                            top: -68px; }
                        @media screen and (max-width: 508px) {
                            top: -98px; }
                        @media screen and (max-width: 440px) {
                            position: absolute !important;
                            top: 625px;
                            right: 70px !important; }
                        @media screen and (max-width: 431px) {
                            right: 65px !important;
                            top: 624px; }
                        @media screen and (max-width: 426px) {
                            top: 578px;
                            right: 70px !important; }
                        @media screen and (max-width: 415px) {
                            top: 579px;
                            right: 70px !important; }
                        @media screen and (max-width: 413px) {
                            top: 611px;
                            right: 73px !important; }
                        @media screen and (max-width: 391px) {
                            top: 584px;
                            right: 68px !important; }
                        @media screen and (max-width: 376px) {
                            top: 540px;
                            right: 53px !important; }
                        @media screen and (max-width: 361px) {
                            top: 540px;
                            right: 46px !important; }
                        @media screen and (max-width: 345px) {
                            top: 541px;
                            right: 43px !important; }
                        @media screen and (max-width: 321px) {
                            top: 499px;
                            right: 37px !important; } } }

                .row10 {
                    .contents {
                        @media screen and (max-width: 800px) {
                            top: -17px; }
                        @media screen and (max-width: 768px) {
                            top: 957px;
                            left: 176px;
                            position: absolute !important; }
                        @media screen and (max-width: 766px) {
                            top: 931px;
                            left: 171px; }
                        @media screen and (max-width: 758px) {
                            top: 919px; }
                        @media screen and (max-width: 754px) {
                            top: 914px; }
                        @media screen and (max-width: 748px) {
                            top: 910px; }
                        @media screen and (max-width: 747px) {
                            top: 908px;
                            left: 177px; }
                        @media screen and (max-width: 743px) {
                            top: 903px;
                            left: 169px; }
                        @media screen and (max-width: 736px) {
                            top: 900px;
                            left: 179px; }
                        @media screen and (max-width: 730px) {
                            top: 886px; }
                        @media screen and (max-width: 724px) {
                            top: 876px;
                            left: 174px; }
                        @media screen and (max-width: 720px) {
                            top: 877px;
                            left: 164px; }
                        @media screen and (max-width: 716px) {
                            top: 870px;
                            left: 172px; }
                        @media screen and (max-width: 711px) {
                            top: 865px;
                            left: 170px; }
                        @media screen and (max-width: 708px) {
                            top: 902px;
                            left: 173px; }
                        @media screen and (max-width: 704px) {
                            top: 893px; }
                        @media screen and (max-width: 701px) {
                            top: 891px; }
                        @media screen and (max-width: 690px) {
                            top: 883px; }
                        @media screen and (max-width: 693px) {
                            top: 885px; }
                        @media screen and (max-width: 681px) {
                            top: 908px;
                            left: 160px; }
                        @media screen and (max-width: 678px) {
                            top: 901px;
                            left: 159px; }
                        @media screen and (max-width: 675px) {
                            top: 889px;
                            left: 156px; }
                        @media screen and (max-width: 667px) {
                            left: 162px; }
                        @media screen and (max-width: 665px) {
                            left: 165px;
                            top: 880px; }
                        @media screen and (max-width: 661px) {
                            left: 175px;
                            top: 879px; }
                        @media screen and (max-width: 648px) {
                            left: 158px;
                            top: 874px; }
                        @media screen and (max-width: 638px) {
                            top: 918px;
                            left: 133px; }
                        @media screen and (max-width: 631px) {
                            top: 910px; }
                        @media screen and (max-width: 629px) {
                            top: 905px;
                            left: 138px; }
                        @media screen and (max-width: 611px) {
                            top: 914px;
                            left: 120px; }
                        @media screen and (max-width: 609px) {
                            top: 908px;
                            left: 128px; }
                        @media screen and (max-width: 605px) {
                            left: 117px;
                            top: 924px; }
                        @media screen and (max-width: 591px) {
                            left: 117px; }
                        @media screen and (max-width: 589px) {
                            top: 906px;
                            left: 96px; }
                        @media screen and (max-width: 579px) {
                            top: 900px;
                            left: 95px; }
                        @media screen and (max-width: 572px) {
                            top: 898px;
                            left: 91px; }
                        @media screen and (max-width: 560px) {
                            top: 895px;
                            left: 97px; }
                        @media screen and (max-width: 556px) {
                            top: 850px;
                            left: 100px; }
                        @media screen and (max-width: 555px) {
                            top: 849px;
                            left: 88px; }
                        @media screen and (max-width: 552px) {
                            top: 847px;
                            left: 88px; }
                        @media screen and (max-width: 545px) {
                            top: 709px;
                            left: 94px; }
                        @media screen and (max-width: 535px) {
                            top: 693px;
                            left: 92px; }
                        @media screen and (max-width: 531px) {
                            top: 693px;
                            left: 91px; }
                        @media screen and (max-width: 524px) {
                            top: 693px;
                            left: 91px; }
                        @media screen and (max-width: 518px) {
                            top: 689px;
                            left: 89px; }
                        @media screen and (max-width: 512px) {
                            top: 675px;
                            left: 87px; }
                        @media screen and (max-width: 508px) {
                            top: 646px;
                            left: 86px; }
                        @media screen and (max-width: 503px) {
                            top: 634px;
                            left: 85px; }
                        @media screen and (max-width: 440px) {
                            left: 83px;
                            top: 691px; }
                        @media screen and (max-width: 431px) {
                            left: 80px;
                            top: 690px; }
                        @media screen and (max-width: 426px) {
                            top: 638px;
                            left: 67px; }
                        @media screen and (max-width: 415px) {
                            top: 676px;
                            left: 70px; }
                        @media screen and (max-width: 391px) {
                            left: 62px;
                            top: 647px; }
                        @media screen and (max-width: 376px) {
                            top: 599px;
                            left: 50px; }
                        @media screen and (max-width: 361px) {
                            top: 600px;
                            left: 43px; }
                        @media screen and (max-width: 345px) {
                            top: 601px;
                            left: 40px; }
                        @media screen and (max-width: 321px) {
                            top: 551px;
                            left: 35px; } } }


                .row3, .row5, .row7, .row9 {
                    @media screen and (max-width: 860px) {
                        td {
                            align-content: center; }
                        .contents {
                            position: relative;
                            float: left;
                            align-content: end;
                            right: 100px; } }
                    @media screen and (max-width: 795px) {
                        .contents {
                            right: 100px; } }
                    @media screen and (max-width: 743px) {
                        .contents {
                            right: 96px; } }
                    @media screen and (max-width: 736px) {
                        .contents {
                            right: 93px; } }
                    @media screen and (max-width: 701px) {
                        .contents {
                            right: 86px; }
                        td {
                            padding: 0; } }
                    @media screen and (max-width: 557px) {
                        .contents {
                            right: 81px; } }
                    @media screen and (max-width: 546px) {
                        .contents {
                            right: 80px; } }
                    @media screen and (max-width: 544px) {
                        .contents {
                            right: 70px; } }
                    @media screen and (max-width: 508px) {
                        .contents {
                            right: 65px; } }
                    @media screen and (max-width: 440px) {
                        .contents {
                            font-size: 14px;
                            right: 62px; } }
                    @media screen and (max-width: 431px) {
                        .contents {
                            right: 58px; } }
                    @media screen and (max-width: 426px) {
                        .contents {
                            //position: absolute
                            right: 62px;
                            font-size: 15px; } }
                    @media screen and (max-width: 415px) {
                        .contents {
                            right: 67px;
                            font-size: small; } }
                    @media screen and (max-width: 413px) {
                        .contents {
                            right: 70px; } }
                    @media screen and (max-width: 376px) {
                        .contents {
                            right: 49px; }
                        td {
                            padding: 0; } }
                    @media screen and (max-width: 361px) {
                        .contents {
                            right: 43px; } }
                    @media screen and (max-width: 345px) {
                        .contents {
                            right: 37px; } }
                    @media screen and (max-width: 321px) {
                        .contents {
                            right: 28px; } } }

                .row11 {
                    height: 150px;
                    @media screen and (max-width: 769px) {
                        height: 105px; }
                    @media screen and (max-width: 589px) {
                        height: 103px; }
                    @media screen and (max-width: 586px) {
                        height: 90px; }
                    @media screen and (max-width: 440px) {
                        height: 10px; }
                    @media screen and (max-width: 426px) {
                        height: 40px; } }

                .mobile-hidden-desc {
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    position: relative;
                    display: none;
                    transition: transform .5s;
                    //transition: max-height 0.5s ease-out

                    p {
                        @media screen and (max-width: 860px) {
                            position: absolute;
                            width: 270px;
                            padding: 6px;
                            font-size: 16px;
                            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
                            color: white;
                            line-height: 1.4;
                            font-style: italic; }
                        @media screen and (max-width: 768px) {
                            font-size: 15px; }
                        @media screen and (max-width: 641px) {
                            font-size: 13.5px;
                            width: 250px; }
                        @media screen and (max-width: 440px) {
                            font-size: 12.5px;
                            line-height: 1.2; }
                        @media screen and (max-width: 581px) {
                            width: 217px; }
                        @media screen and (max-width: 440px) {
                            width: 160px; }
                        @media screen and (max-width: 426px) {
                            font-size: 13px;
                            width: 130px;
                            line-height: 1.2; }
                        @media screen and (max-width: 415px) {
                            font-size: 12px; }
                        @media screen and (max-width: 376px) {
                            width: 130px; }
                        @media screen and (max-width: 361px) {
                            width: 127px !important; }
                        @media screen and (max-width: 321px) {
                            width: 115px;
                            font-size: 9.5px; } }

                    .right {
                        @media screen and (max-width: 860px) {
                            border-radius: 15px 0 15px 0; }
                        @media screen and (max-width: 426px) {
                            padding: 0; } }

                    .left {
                        @media screen and (max-width: 860px) {
                            border-radius: 15px 0 15px 0;
                            padding-left: 10px; }
                        @media screen and (max-width: 701px) {
                            padding-left: 30px; }
                        @media screen and (max-width: 426px) {
                            padding: 0; } } }

                .toggle-icon {
                    cursor: pointer;
                    color: $gold-color; }

                .bc-content {
                    @media screen and (max-width: 860px) {
                        top: -38px;
                        right: 102px; }
                    @media screen and (max-width: 768px) {
                        top: -52px;
                        right: 96px; }
                    @media screen and (max-width: 545px) {
                        top: -59px;
                        right: 96px; }
                    @media screen and (max-width: 544px) {
                        right: 80px; }
                    @media screen and (max-width: 440px) {
                        top: -13px;
                        right: 82px; }
                    @media screen and (max-width: 426px) {
                        top: -26px;
                        right: 72px; }
                    @media screen and (max-width: 415px) {
                        top: -17px;
                        right: 72px; }
                    @media screen and (max-width: 391px) {
                        top: -16px;
                        right: 72px; }
                    @media screen and (max-width: 376px) {
                        top: -31px;
                        right: 44px; }
                    @media screen and (max-width: 321px) {
                        top: -38px;
                        right: 41px; }

                    p {
                        @media screen and (max-width: 680px) {
                            width: 240px; }
                        @media screen and (max-width: 440px) {
                            width: 157px; }
                        @media screen and (max-width: 415px) {
                            width: 140px !important; }
                        @media screen and (max-width: 376px) {
                            width: 127px !important; }
                        @media screen and (max-width: 321px) {
                            width: 111px !important; } } }

                .gw-content {
                    @media screen and (max-width: 860px) {
                        top: -37px;
                        left: 17px; }
                    @media screen and (max-width: 795px) {
                        top: -46px; }
                    @media screen and (max-width: 768px) {
                        top: -63px;
                        left: -13px; }
                    @media screen and (max-width: 545px) {
                        top: -87px;
                        left: -14px; }
                    @media screen and (max-width: 440px) {
                        top: -25px;
                        left: -17px; }
                    @media screen and (max-width: 426px) {
                        top: -37px;
                        left: 11px; }
                    @media screen and (max-width: 415px) {
                        top: -36px;
                        left: 7px; }
                    @media screen and (max-width: 391px) {
                        top: -44px;
                        left: 7px; }
                    @media screen and (max-width: 376px) {
                        top: -55px;
                        left: 8px; }
                    @media screen and (max-width: 361px) {
                        top: -46px;
                        left: 4px; }
                    @media screen and (max-width: 345px) {
                        top: -52px;
                        left: 4px; }
                    @media screen and (max-width: 321px) {
                        top: -45px;
                        left: 6px; }

                    p {
                        @media screen and (max-width: 662px) {
                            width: 230px; }
                        @media screen and (max-width: 600px) {
                            width: 210px; }
                        @media screen and (max-width: 545px) {
                            width: 200px; }
                        @media screen and (max-width: 530px) {
                            width: 192px; }
                        @media screen and (max-width: 440px) {
                            padding: 0;
                            width: 180px; }
                        @media screen and (max-width: 426px) {
                            width: 167px; }
                        @media screen and (max-width: 415px) {
                            width: 140px !important; }
                        @media screen and (max-width: 376px) {
                            width: 130px !important; }
                        @media screen and (max-width: 345px) {
                            width: 123px !important; }
                        @media screen and (max-width: 321px) {
                            width: 111px !important; } } }





                .edt-content {
                    @media screen and (max-width: 860px) {
                        top: -34px;
                        right: 100px; }
                    @media screen and (max-width: 800px) {
                        top: -37px; }
                    @media screen and (max-width: 768px) {
                        top: -47px;
                        right: 91px; }
                    @media screen and (max-width: 545px) {
                        top: -69px;
                        right: 90px; }
                    @media screen and (max-width: 544px) {
                        right: 82px; }
                    @media screen and (max-width: 491px) {
                        top: -24px;
                        right: 70px; }
                    @media screen and (max-width: 440px) {
                        top: -33px;
                        right: 80px; }
                    @media screen and (max-width: 426px) {
                        top: 29px;
                        right: 67px; }
                    @media screen and (max-width: 415px) {
                        top: -23px;
                        right: 70px; }
                    @media screen and (max-width: 376px) {
                        top: -33px;
                        right: 45px; }
                    @media screen and (max-width: 345px) {
                        top: -32px;
                        right: 47px; }
                    @media screen and (max-width: 321px) {
                        top: -35px;
                        right: 45px; } }

                .ils-content {
                    @media screen and (max-width: 860px) {
                        top: -33px;
                        left: 17px; }
                    @media screen and (max-width: 800px) {
                        top: -25px;
                        left: -77px; }
                    @media screen and (max-width: 795px) {
                        top: -32px;
                        left: 20px; }
                    @media screen and (max-width: 545px) {
                        top: -86px;
                        left: 13px; }
                    @media screen and (max-width: 768px) {
                        top: -42px;
                        left: -13px; }
                    @media screen and (max-width: 544px) {
                        top: -68px;
                        left: -9px; }
                    @media screen and (max-width: 508px) {
                        top: -82px;
                        left: -12px; }
                    @media screen and (max-width: 491px) {
                        top: -33px;
                        left: 13px; }
                    @media screen and (max-width: 440px) {
                        top: -54px;
                        left: -11px; }
                    @media screen and (max-width: 431px) {
                        top: -40px; }
                    @media screen and (max-width: 426px) {
                        top: -39px;
                        left: 11px; }
                    @media screen and (max-width: 376px) {
                        top: -31px;
                        left: 9px; }
                    @media screen and (max-width: 321px) {
                        top: -37px;
                        left: 6px; }
                    p {
                        @media screen and (max-width: 321px) {
                            width: 110px !important; } } }

                .dl-content {
                    @media screen and (max-width: 860px) {
                        top: -50px;
                        right: 100px; }
                    @media screen and (max-width: 800px) {
                        top: -46px;
                        right: 94px; }
                    @media screen and (max-width: 795px) {
                        top: -55px; }
                    @media screen and (max-width: 768px) {
                        top: -68px;
                        right: 90px; }
                    @media screen and (max-width: 545px) {
                        top: -99px;
                        right: 89px; }
                    @media screen and (max-width: 544px) {
                        top: -83px;
                        right: 81px; }
                    @media screen and (max-width: 530px) {
                        top: -99px; }
                    @media screen and (max-width: 508px) {
                        top: -118px;
                        right: 81px; }
                    @media screen and (max-width: 490px) {
                        top: -49px;
                        right: 71px; }
                    @media screen and (max-width: 440px) {
                        top: -90px;
                        right: 83px; }
                    @media screen and (max-width: 431px) {
                        top: -73px;
                        right: 82px; }
                    @media screen and (max-width: 426px) {
                        top: -20px;
                        right: 68px; }
                    @media screen and (max-width: 415px) {
                        top: -50px;
                        right: 74px; }
                    @media screen and (max-width: 376px) {
                        top: -65px;
                        right: 49px; }
                    @media screen and (max-width: 361px) {
                        top: -65px;
                        right: 52px; }
                    @media screen and (max-width: 345px) {
                        right: 49px; }
                    @media screen and (max-width: 321px) {
                        top: -55px;
                        right: 44px; }

                    p {
                        @media screen and (max-width: 426px) {
                            width: 152px !important; }
                        @media screen and (max-width: 376px) {
                            width: 129px !important; }
                        @media screen and (max-width: 321px) {
                            width: 124px !important; } } }

                .dis-content {
                    @media screen and (max-width: 860px) {
                        top: -38px;
                        left: 17px; }
                    @media screen and (max-width: 800px) {
                        top: -35px;
                        left: -11px; }
                    @media screen and (max-width: 795px) {
                        top: -52px;
                        left: 31px; }
                    @media screen and (max-width: 768px) {
                        top: -62px;
                        left: -7px; }
                    @media screen and (max-width: 545px) {
                        top: -129px;
                        left: -13px; }
                    @media screen and (max-width: 544px) {
                        top: -98px;
                        left: -13px; }
                    @media screen and (max-width: 512px) {
                        top: -105px;
                        left: -17px; }
                    @media screen and (max-width: 508px) {
                        top: -125px;
                        left: -17px; }
                    @media screen and (max-width: 440px) {
                        top: -117px; }
                    @media screen and (max-width: 431px) {
                        top: -83px; }
                    @media screen and (max-width: 426px) {
                        top: -58px;
                        left: 9px; }
                    @media screen and (max-width: 415px) {
                        top: -59px;
                        left: 12px; }
                    @media screen and (max-width: 413px) {
                        top: -59px;
                        left: 9px; }
                    @media screen and (max-width: 391px) {
                        left: 9px;
                        top: -65px; }
                    @media screen and (max-width: 376px) {
                        left: 9px; }
                    @media screen and (max-width: 321px) {
                        top: -48px;
                        left: 5px; }

                    p {
                        @media screen and (max-width: 545px) {
                            width: 210px; }
                        @media screen and (max-width: 440px) {
                            width: 170px; }
                        @media screen and (max-width: 426px) {
                            width: 156px; }
                        @media screen and (max-width: 413px) {
                            width: 148px !important; }
                        @media screen and (max-width: 376px) {
                            width: 125px !important; }
                        @media screen and (max-width: 321px) {
                            width: 113px !important; } } }

                .print-content {
                    @media screen and (max-width: 860px) {
                        top: -50px;
                        right: 100px; }
                    @media screen and (max-width: 800px) {
                        top: -35px; }
                    @media screen and (max-width: 795px) {
                        top: -52px;
                        right: 95px; }
                    @media screen and (max-width: 768px) {
                        top: -70px;
                        right: 90px; }
                    @media screen and (max-width: 641px) {
                        top: -54px;
                        right: 96px; }
                    @media screen and (max-width: 545px) {
                        top: -132px;
                        right: 91px; }
                    @media screen and (max-width: 544px) {
                        top: -95px;
                        right: 82px; }
                    @media screen and (max-width: 512px) {
                        top: -111px;
                        right: 82px; }
                    @media screen and (max-width: 508px) {
                        top: -133px;
                        right: 78px; }
                    @media screen and (max-width: 440px) {
                        top: -128px;
                        right: 82px; }
                    @media screen and (max-width: 431px) {
                        top: -79px; }
                    @media screen and (max-width: 426px) {
                        top: -68px;
                        right: 65px; }
                    @media screen and (max-width: 415px) {
                        top: -59px;
                        right: 71px; }
                    @media screen and (max-width: 413px) {
                        top: -62px;
                        right: 72px; }
                    @media screen and (max-width: 391px) {
                        top: -61px;
                        right: 70px; }
                    @media screen and (max-width: 376px) {
                        top: -68px;
                        right: 48px; }
                    @media screen and (max-width: 321px) {
                        top: -51px;
                        right: 45px; }

                    p {
                        @media screen and (max-width: 426px) {
                            width: 146px !important; }
                        @media screen and (max-width: 376px) {
                            width: 125px !important; }
                        @media screen and (max-width: 321px) {
                            width: 114px !important; } } }

                .audb-content {
                    @media screen and (max-width: 860px) {
                        top: -34px;
                        left: 17px; }
                    @media screen and (max-width: 800px) {
                        top: -30px;
                        left: -13px; }
                    @media screen and (max-width: 795px) {
                        top: -66px;
                        left: 9px; }
                    @media screen and (max-width: 768px) {
                        top: -73px;
                        left: -11px; }
                    @media screen and (max-width: 545px) {
                        top: -139px;
                        left: -18px; }
                    @media screen and (max-width: 544px) {
                        top: -123px;
                        left: -14px; }
                    @media screen and (max-width: 530px) {
                        top: -119px; }
                    @media screen and (max-width: 508px) {
                        top: -149px;
                        left: -14px; }
                    @media screen and (max-width: 500px) {
                        top: -154px; }
                    @media screen and (max-width: 440px) {
                        top: -162px; }
                    @media screen and (max-width: 431px) {
                        top: -108px; }
                    @media screen and (max-width: 426px) {
                        top: -84px;
                        left: 12px; }
                    @media screen and (max-width: 415px) {
                        top: -80px;
                        left: 17px; }
                    @media screen and (max-width: 413px) {
                        top: -76px;
                        left: 11px; }
                    @media screen and (max-width: 391px) {
                        top: -74px;
                        left: 14px; }
                    @media screen and (max-width: 376px) {
                        top: -80px;
                        left: 7px; }
                    @media screen and (max-width: 361px) {
                        top: -76px;
                        left: 6px; }
                    @media screen and (max-width: 321px) {
                        top: -58px;
                        left: 9px; }

                    p {
                        @media screen and (max-width: 413px) {
                            width: 140px !important; }
                        @media screen and (max-width: 376px) {
                            width: 132px !important; }
                        @media screen and (max-width: 321px) {
                            width: 116px !important; } } }

                .mkt-content {
                    @media screen and (max-width: 860px) {
                        top: -33px;
                        right: 100px; }
                    @media screen and (max-width: 800px) {
                        top: -19px; }
                    @media screen and (max-width: 791px) {
                        top: -65px; }
                    @media screen and (max-width: 768px) {
                        top: -70px;
                        right: 90px; }
                    @media screen and (max-width: 545px) {
                        top: -148px;
                        right: 92px; }
                    @media screen and (max-width: 544px) {
                        top: -105px;
                        right: 84px; }
                    @media screen and (max-width: 508px) {
                        top: -153px;
                        right: 79px; }
                    @media screen and (max-width: 440px) {
                        top: -170px;
                        right: 84px; }
                    @media screen and (max-width: 431px) {
                        top: -105px;
                        right: 84px; }
                    @media screen and (max-width: 426px) {
                        top: -75px;
                        right: 67px; }
                    @media screen and (max-width: 415px) {
                        top: -73px;
                        right: 69px; }
                    @media screen and (max-width: 391px) {
                        top: -73px;
                        right: 69px; }
                    @media screen and (max-width: 376px) {
                        top: -52px;
                        right: 46px; }
                    @media screen and (max-width: 321px) {
                        top: -59px;
                        right: 43px; }
                    p {
                        @media screen and (max-width: 321px) {
                            width: 117px !important; } } }

                .dl-content, .print-content, .mkt-content {
                    p {
                        @media screen and (max-width: 662px) {
                            width: 230px; } } }

                .dl-content, .print-content, .mkt-content, .bc-content, .edt-content {
                    p {
                        @media screen and (max-width: 600px) {
                            width: 210px; }
                        @media screen and (max-width: 530px) {
                            width: 192px; }
                        @media screen and (max-width: 440px) {
                            width: 153px; }
                        @media screen and (max-width: 415px) {
                            width: 140px; }
                        @media screen and (max-width: 376px) {
                            width: 130px; } } }

                .ils-content, .dis-content, .audb-content {
                    p {
                        @media screen and (max-width: 644px) {
                            width: 250px; }
                        @media screen and (max-width: 634px) {
                            width: 225px; }
                        @media screen and (max-width: 589px) {
                            width: 210px; }
                        @media screen and (max-width: 440px) {
                            width: 162px; }
                        @media screen and (max-width: 432px) {
                            width: 183px; }
                        @media screen and (max-width: 426px) {
                            width: 155px; }
                        @media screen and (max-width: 415px) {
                            width: 138px; }
                        @media screen and (max-width: 376px) {
                            width: 125px; } } } } } }

    .home-packages-div {
        margin: 0 auto;
        @media screen and (max-width: 800px) {
            padding: 0 1em; }
        .title {
            margin: 0 auto; }
        p {
            align-items: center; }
        .card-div {
            padding: 2.5em 6em;
            margin: 0 auto;
            @media screen and (max-width: 1025px) {
                padding: 2.5em 2em; }

            @media screen and (max-width: 800px) {
                padding: 1em 0;
                text-align: center; }

            .card-box {
                @media only screen and (min-width: 768px) {
                    -webkit-transition: all 200ms ease-in;
                    -webkit-transform: scale(1);
                    -ms-transition: all 200ms ease-in;
                    -ms-transform: scale(1);
                    -moz-transition: all 200ms ease-in;
                    -moz-transform: scale(1);
                    transition: all 100ms ease-in;
                    transform: transform .4;

                    &:hover {
                        z-index: 2;
                        -webkit-transition: all 200ms ease-in;
                        -webkit-transform: scale(1.2);
                        -ms-transition: all 200ms ease-in;
                        -ms-transform: scale(1.1);
                        -moz-transition: all 200ms ease-in;
                        -moz-transform: scale(1.1);
                        transition: all 200ms ease-in;
                        transform: scale(1.1); }

                    &:hover:before {
                        width: 30%;
                        height: 40%;
                        transition: all 300ms ease-in; }

                    &:hover:after {
                        width: 20%;
                        height: 30%;
                        transition: all 300ms ease-in; } }

                @media screen and (max-width: 960px) {
                    h5 {
                        text-align: center;
                        font-size: 16px; } }
                @media screen and (max-width: 800px) {
                    h5 {
                        font-size: 15px; } }
                @media screen and (max-width: 500px) {
                    h5 {
                        font-size: 12px; } } }
            .card {
                background-color: $mutedblue-color;
                .btn-learnmore {
                    background-color: $gold-color;
                    border-radius: 20px;
                    margin: 0 auto;
                    font-weight: 500;
                    color: #000;
                    border: none;
                    border-radius: 20px;
                    padding: 10px 20px;
                    font-size: 16px;
                    font-weight: bold;
                    display: inline-flex;
                    align-items: center;
                    text-decoration: none;
                    transition: background-color 0.3s ease;
                    height: 35px;

                    @media screen and (max-width: 960px) {
                        height: 25px;
                        margin: 3px 0 5px;
                        padding: 4px 9px;
                        font-size: 13px; }
                    @media screen and (max-width: 800px) {
                        margin: 2px 30px 2px;
                        padding: 1px 8px; }
                    @media screen and (max-width: 768px) {
                        margin: 0 auto;
                        padding: 2px 14px;
                        font-size: 12px; }
                    @media screen and (max-width: 640px) {
                        padding: 2px 17px;
                        font-size: 16px;
                        margin-bottom: 3em; }
                    @media screen and (max-width: 540px) {
                        padding: 7px 14px;
                        font-size: 13px; }
                    @media screen and (max-width: 440px) {
                        padding: 7px 12px;
                        font-size: 11px; }
                    @media screen and (max-width: 426px) {
                        font-size: 11px; }
                    @media screen and (max-width: 361px) {
                        font-size: 8px;
                        padding: 5px 9px; }
                    i {
                        width: 30px;
                        height: 35px;
                        background-color: #000;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $gold-color;
                        margin-right: -20px;

                        @media screen and (max-width: 960px) {
                            width: 25px;
                            height: 25px;
                            position: absolute;
                            right: 18px; }
                        @media screen and (max-width: 800px) {
                            right: 50px; }
                        @media screen and (max-width: 768px) {
                            right: auto;
                            position: relative; } }

                    span {
                        margin-right: 10px; }
                    img {
                        cursor: pointer; } } } } } }

.author-testimonials-videos {
    @media only screen and (min-width: 768px) {
        padding-top: 4em; }
    @media only screen and (max-width: 767px) {
        padding-top: 2em; }
    @media only screen and (max-width: 430px) {
        padding: 2em 0; }

    .main-iframe {
        width: 880px;
        height: 430px;
        @media screen and (min-width: 1540px) {
            width: 990px;
            height: 550px; }
        @media screen and (max-width: 800px) {
            width: 723px;
            height: 375px; }
        @media screen and (max-width: 640px) {
            width: 595px; }
        @media screen and (max-width: 600px) {
            width: 510px; }
        @media screen and (max-width: 440px) {
            width: 400px; }
        @media screen and (max-width: 420px) {
            width: 382px; } }
    h2 {
        padding: 1em;
        text-align: center;
        @media screen and (max-width: 540px) {
            padding: 0; }
        @media only screen and (max-width: 462px) {
            font-size: 30px; }
        @media only screen and (max-width: 400px) {
            white-space: nowrap;
            font-size: 29px; }
        @media only screen and (max-width: 361px) {
            font-size: 27px; } }
    .small-ytvideos {
        padding-top: 3em;
        .star-container {
            display: flex;
            gap: 5px;
            justify-content: center; }
        .card {
            align-items: center;

            iframe {
                width: 450px;
                height: 230px;
                @media screen and (min-width: 1540px) {
                    width: 500px;
                    height: 260px; }
                @media screen and (max-width: 800px) {
                    width: 235px;
                    height: 210px; }
                @media screen and (max-width: 767px) {
                    width: 380px;
                    height: 235px; } } }

        .desc {
            text-align: center;

            @media screen and (max-width: 767px) {
                margin-bottom: 4em; }

            .author-name {
                font-weight: 400;
                font-size: large;
                margin-bottom: 0;
                font-weight: 600; }

            .book-title {
                font-style: italic;
                font-weight: 600;
                font-size: 15px; }
            .header {
                font-weight: 600; }
            .content {
                text-align: justify;
                @media screen and (min-width: 1600px) {
                    padding: 0px 3.5em; } }
            p {
                font-family: $font-body; } } } }


.homepage-guide-to-publishing {
    padding: 0 0 2em;
    @media screen and (max-width: 769px) {
        padding: 0 1em 2em; }
    @media screen and (max-width: 440px) {
        padding: 0 10px 2em; }

    h2 {
        font-weight: 400;
        padding-bottom: .5em;
        font-size: 45px;
        @media screen and (min-width: 1540px) {
            font-size: 45px;
            letter-spacing: 3px; }
        @media screen and (max-width: 769px) {
            font-size: 27px;
            white-space: nowrap; }
        @media screen and (max-width: 500px) {
            font-size: 23px; } }
    p {
        font-size: 17px; }

    ul {
        padding: 0px 0 5px 0;
        margin-left: 2rem;
        @media screen and (min-width: 1540px) {
            font-size: x-large; }
        @media screen and (max-width: 500px) {
            font-size: 16px;
            margin-left: 1rem; }
        li {
            margin-bottom: .5rem;
            font-size: 17px;
            align-items: center; } }

    .div-container {
        justify-content: right;
        display: flex;
        @media screen and (max-width: 500px) {
            vertical-align: middle;
            align-items: center; } }
    .img-container {
        width: 270px;
        height: auto;
        @media screen and (min-width: 1540px) {
            width: 300px; }

        @media screen and (max-width: 960px) {
            width: 100%; } }

    .guide-button {
        background-color: $gold-color;
        text-align: center;
        display: inline-block;
        padding: 10px 48px;
        color: #212529;
        font-weight: bold;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;
        position: relative;
        @media screen and (max-width: 769px) {
            padding: 8px 20px; }
        a {
            font-size: 19px;
            font-weight: 600;

            @media screen and (max-width: 769px) {
                font-size: 17px; } } }

    .guide {
        padding: 0 2em;

        @media screen and (max-width: 960px) {
            padding: 0; } }

    .mobile-view-3d-guide {
        img {
            position: relative;
            top: 40px;
            @media screen and (max-width: 769px) {
                top: 0; }
            @media screen and (max-width: 560px) {
                top: 100px; } } } }
.homepage-featured-media {
    padding: 0 1em 1em 0;
    background-color: $mutedblue-color;
    text-align: center;
    @media screen and (min-width: 1540px) {
        padding: 2em; }

    @media screen and (max-width: 960px) {
        padding: 0; }

    @media screen and (max-width: 1025px) {
        .custom-col-4 {
            flex: 0 0 27.3333%;
            max-width: 27.3333%; }

        .custom-col-6 {
            flex: 0 0 40%;
            max-width: 30%; }

        .custom-sm-3 {
            max-width: 22.3333%;
            flex: 0 0 22.3333%;
            align-content: center;
            margin: 0 auto; } }

    @media screen and (max-width: 760px) {
        .custom-col-4 {
            flex: 0 0 33.3333%;
            max-width: 33.3333%; }

        .custom-col-6 {
            flex: 0 0 50%;
            max-width: 40%; } }

    .homepage-titles {
        @media screen and (max-width: 425px) {
            padding-bottom: 0; } }

    .div-wrapper {
        align-items: center; }

    .custom-col-2 {
        flex: 0 0 13.6667%;
        max-width: 13.6667%; }

    h2 {
        font-weight: 400;
        padding-bottom: 1em; }

    .icon1, .icon2, .icon6, .icon4, .icon12, .icon7 {
        display: flex;
        align-items: end;
        justify-content: center;
        margin: 0 auto;
        @media screen and (max-width: 825px) {
            height: 60px; } }

    .icon3, .icon8, .icon10, .icon5, .icon11, .icon14, .icon9, .icon13 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        @media screen and (max-width: 825px) {
            height: 60px; }
        @media screen and (max-width: 431px) {
            justify-content: right;
            height: 50px; } }

    .icon1 {
        top: -10px;
        @media screen and (max-width: 1025px) {
            top: 0; }
        img {
            width: 60%; } }
    .icon2 {
        img {
            width: 80%; } }
    .icon3 {
        img {
            width: 70%;
            @media screen and (max-width: 1025px) {
                width: 55%; } } }
    .icon6 {
        img {
            width: 75%; } }
    .icon4 {
        img {
            width: 80%;
            @media screen and (max-width: 541px) {
                width: 95%; } } }
    .icon5 {
        margin: -20px;
        @media screen and (max-width: 541px) {
            justify-content: end !important; }
        img {
            width: 90%;
            @media screen and (max-width: 1025px) {
                width: 70%; }
            @media screen and (max-width: 541px) {
                width: 90% !important; } } }
    .icon8 {
        img {
            width: 80%;
            @media screen and (max-width: 1025px) {
                width: 60%; } } }

    .icon14 {
        img {
            width: 85%;
            @media screen and (max-width: 1025px) {
                width: 75%; } } }
    .icon9 {
        img {
            width: 70%;
            @media screen and (max-width: 760px) {
                width: 55%; } } }


    .icon10 {
        @media screen and (max-width: 1025px) {
            top: 0; }
        @media screen and (max-width: 431px) {
            justify-content: end !important; }
        @media screen and (max-width: 541px) {
            justify-content: end !important; }
        img {
            width: 65%;
            @media screen and (max-width: 1025px) {
                width: 47%; }
            @media screen and (max-width: 541px) {
                width: 55% !important; } } }

    .icon11 {
        img {
            width: 90%;
            @media screen and (max-width: 1025px) {
                width: 70%; }
            @media screen and (max-width: 825px) {
                width: 80%; }
            @media screen and (max-width: 541px) {
                width: 90%; } } }
    .icon12 {
        img {
            width: 80%;
            @media screen and (max-width: 541px) {
                width: 90%; } } }
    .icon13 {
        img {
            width: 75%; } }

    .icon12, .icon7 {
        left: 20px; }

    .icon1, .icon3, .icon8, .icon10, .icon14 {
        @media screen and (max-width: 768px) {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 75%;
                @media screen and (max-width: 760px) {
                    width: 45%; } } } }
    .icon1, .icon3, .icon8, .icon10, .icon14, .icon9 {
        @media screen and (max-width: 700px) {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 60%; } } } }

.homepage-your-story-our-mission {
    padding-top: 3em;
    padding-bottom: 3em;

    .your-story-our-mission {
        margin: 0 auto; }

    .books-published {
        h1 {
            font-size: 50px;
            letter-spacing: 3px;

            @media screen and (min-width: 1540px) {
                font-size: 60px; }
            @media screen and (max-width: 768px) {
                font-size: xx-large; } } } }

.homepage-titles {
    font-weight: 400;
    font-size: xxx-large;
    text-align: center;
    @media screen and (max-width: 1540px) {
        font-size: 45px; }
    @media screen and (max-width: 1024px) {
        font-size: xx-large; }


    @media screen and (max-width: 960px) {
        font-size: xx-large; } }

.success-story-div {
    .book-title {
        font-weight: 600; }

    .img-div {
        @media screen and (max-width: 800px) {
            display: flex;
            align-items: center; } } }

.publishing-guide {
    padding: 2rem 4rem 0 4rem;
    @media screen and (max-width: 1025px) {
        padding: 2rem; }
    @media screen and (max-width: 800px) {
        padding: 0; }
    @media screen and (max-width: 530px) {
        padding: 0 .5em; }

    .guide-content {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2rem;

        @media screen and (max-width: 768px) {
            margin: 0 auto; }

        h2 {
            font-size: 42px;
            font-weight: 100;
            padding-bottom: 2rem;
            @media screen and (max-width: 800px) {
                padding: 0; }
            @media only screen and (max-width: 768px) {
                font-size: 32px;
                padding: 0 0 0 .5em; }
            @media only screen and (max-width: 440px) {
                padding: 0; } }

        p {
            font-size: 18px; }

        .checklist {
            ul {
                list-style: none;
                padding: 0px 0 1em 0;
                width: 460px;
                @media screen and (max-width: 800px) {
                    padding: 0; }
                @media screen and (max-width: 600px) {
                    width: 280px; } }

            li {
                margin-bottom: 1.2rem;
                font-size: 18px;
                display: flex;
                align-items: center;

                &:before {
                    content: "✔";
                    margin-right: 1rem;
                    color: #2c3e50; } } } }

    img {
        max-width: 100%;
        height: auto;
        margin-left: auto;
        height: auto;
        top: -105px;
        position: relative;

        @media screen and (max-width: 1540px) {
            top: -95px;
            width: 90%; }
        @media screen and (max-width: 1024px) {
            width: 100%;
            top: -55px; }
        @media screen and (min-width: 820px) and (max-width: 992px) {
            top: -25px;
            width: 75%;
            position: absolute;
            right: -50px; }
        @media screen and (max-width: 820px) {
            top: -9px;
            right: -25px;
            width: 80%; }
        @media screen and (max-width: 768px) {
            width: 50%;
            padding-bottom: 5rem;
            margin: 0 auto;
            right: 0;
            top: 20px; } }

    .guide-button {
        text-align: center;
        display: inline-block;
        padding: 10px 48px;
        background-color: #ffbd59;
        color: #212529;
        font-weight: bold;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;
        position: relative;

        a {
            font-size: 19px;
            font-weight: 600; }

        &:hover {
            background-color: $pink-color; }

        @media screen and (max-width: 800px) {
            padding: 8px 20px; } } }

.col-custom-6-homepage {
    @media screen and (min-width: 768px) and (max-width: 992px) {
        width: 50%;
        flex: 0 0 50%;
        max-width: 50%; } }
