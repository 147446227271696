.how-it-works, .marketing-content, .distribution-content, .working-with-book-coach, .book-coach-pricing, .book-coaching-faqs-section, .what-is-book-coaching {
    h3 {
        font-size: xx-large; }

    ul {
        li {
            list-style: none; } }

    i {
        color: $gold-color; } }

.audiobook-publishing {
    .desc {
        display: flex;
        justify-content: center;
        flex-direction: column;
        top: 20%; }

    .img-responsive {
        text-align: left;
        @media screen and (min-width: 320px) and (max-width: 580px) {
            text-align: center; }
        img {
            width: 70%;
            @media screen and (min-width: 320px) and (max-width: 580px) {
                margin-top: 40px; }
            @media screen and (min-width : 768px) and (max-width : 1024px) {
                width: 100%; } } } }

.slick-prev, .slick-next {
    &:before {
        color: #00bed2 !important; } }

.design-intro {
    margin: 3em 0 3em 0;

    p {
        padding: 0 20px 0 20px;
        text-align: justify;
        line-height: 2; } }

.custom-cover-interior {
    margin-bottom: 5em;

    h3,h4 {
        text-align: center;
        margin-top: 1em; }

    .custom-cover-title {
        margin-top: 6em; }

    .carousel-coverdesign {
        .card {
            margin-right: 10px;

            img {
                height: 418px;
                object-fit: cover;
                object-position: top; } } }

    .slick-dots {
        bottom: -50px;
        li {
            button {
                &:before {
                    color: #00bed2 !important; } } } }

    .content-column.price {
            border-left: 2px solid $gold-color; }

    .description {
            text-align: center; }

    .price-text {
        .row {
            justify-content: center; }

        img {
            width: 20%; }

        h4 {
            font-size: 30px;
            font-style: bold; } }

    .row {
        padding-top: 40px; }

    .text {
        padding: 10px; } }

.illustrated-cover {
    h3 {
        text-align: center; }

    .description-text {
        padding: 0 5px 0 30px;
        text-align: justify; } }

.design .card-text {
    padding: 0 15px 0 20px;
    text-align: justify; }

.premium-cover {
    margin-top: 2em;

    p {
        // padding: 0 15px 0 20px
 }        // text-align: justify

    h3 {
        text-align: center;
        margin-top: 2em;
 }        // font-size: xx-large

    .slider-for {
        .card {
          height: 465px; }

        img {
            border: none;
            border-radius: 0;
            box-shadow: none;
            position: absolute;
            height: 575px;
            width: 1200px;
            margin: 0 auto;
            display: block;
            right: auto;
            object-fit: contain !important;
            object-position: top !important;
            max-width: 280px; } }


    .slider-nav {
        position: absolute;
        width: 504px !important;
        left: 0;
        height: 89px;
        padding: 3px 20px 0 25px;


        img {
            width: 100px;
            height: 75px;
            cursor: pointer;
            border: 1px solid #666;
            padding: 2px;
            float: left !important; } } }

.interior-layout {
    margin-top: 2em;

    h3 {
        text-align: center;
        margin-top: 2em;
 }        // font-size: xx-large

    .slider-interior-layout {
        card {
            opacity: 0.5;
            transform: scale(0.7);
            transition: all 300ms ease 0s;
            position: relative;
            overflow: hidden;
            text-align: center;
            margin: 0 2px; }

        img {
            height: auto;
            max-width: 100%;
            border: none;
            border-radius: 0;
            box-shadow: none;
            display: inline-block; } }

    .illustrated-cover {
        margin-top: 5em;

        .illustrated-container {
            width: 50%;
            position: static;

            .illustrated {
                position: relative;
                width: 100%;

                .slider-illustrated {
                    flex-shrink: 0;
                    width: 100%;
                    height: 100%;
                    position: relative;


                    img {
                        height: auto !important;
                        object-fit: cover !important;
                        object-position: top !important; } } } } } }


.slick-center {
    .slick-current {
        width: 0 30px;
        top: 50%;
        transform: scale(1.3);
        transform-origin: center center; }

    .slick-slide {
        text-align: center;
        line-height: 0;
        border: 2px solid #eee;
        transition: transform .3s ease;

        img {
            text-align: center;
            margin: 0 auto; } } }

.underlined-link {
    text-decoration: underline; }

.header-title {
    line-height: 2.5em;
 }    // letter-spacing: 2px

.sub-header-title {
    // letter-spacing: 2px
    margin: 15px 0 15px 0; }

.basic-illustration-images {
    .basic-illustration-carousel {
        width: 100%;
        height: auto;
        margin: 0 auto;

        h2 {
            text-align: center;
            font-size: x-large; }

        img {
            border-radius: unset;
            max-width: 269px;
            height: auto;
            @media screen and (min-width: 320px) and (max-width: 580px) {
                max-width: 400px; } }

        .slider-for {
            zoom: 1;
            height: 100%;
            width: 100%;
            left: 0;

            img {
                border: none;
                border-radius: 0;
                box-shadow: none;
                width: 100%;
                margin: 0 auto;
                display: block;
                left: -1200px;
                right: auto;
                height: 575px;
                object-fit: contain;
                object-position: top; } }

        .wrapper-nav {
            float: left;
            position: absolute;
            z-index: 149;
            width: 100%;
            margin: 0;
            height: 89px;

            .slider-nav {
                position: absolute;
                width: 610px;
                left: 0;
                height: 89px;
                padding: 3px 20px 0 25px;

                img {
                    width: 140px;
                    height: 100px;
                    cursor: pointer;
                    padding: 2px;
                    float: left; } } } } }

.complex-illustration-images {
    .complex-illustration-carousel {
        width: 100%;
        height: auto;
        margin: 0 auto;

        img {
            border-radius: unset;
            max-width: 269px;
            height: auto;
            @media screen and (min-width: 320px) and (max-width: 580px) {
                max-width: 400px; } }

        .wrapper {
            margin-top: 2em; }

        .slider-for {
            zoom: 1;
            height: 100%;
            width: 100%;
            left: 0;

            img {
                border: none;
                border-radius: 0;
                box-shadow: none;
                width: 100%;
                margin: 0 auto;
                display: block;
                left: -1200px;
                right: auto;
                height: 575px; } }

        .wrapper-nav {
            float: left;
            position: absolute;
            z-index: 149;
            width: 100%;
            margin: 0;
            height: 89px;

            .slider-nav {
                position: absolute;
                width: 610px;
                left: 0;
                height: 89px;
                padding: 3px 20px 0 25px;

                img {
                    width: 140px;
                    height: 100px;
                    cursor: pointer;
                    padding: 2px;
                    float: left; } } } } }


.complex-illustrations-type,.basic-illustrations-type {
    li {
        list-style-type: circle;

        &::marker {
            color: $gold-color; } } }

.basic-illustrations-type, .basic-illustration-images {
    margin-top: 1em;

    h3 {
        text-align: center;
        font-size: xx-large; }

    .text {
        font-size: x-large; } }

.complex-illustrations-type {
    margin-top: 3em;

    h3 {
        text-align: center;
        font-size: xx-large; }

    .text {
        font-size: x-large; } }

.complex-illustration-images {
    h2 {
        text-align: center;
        font-size: x-large; }

    h3 {
        text-align: center;
        font-size: xx-large; }

    p {
        line-spacing: 2px;
        font-size: smaller;
        font-style: italic; } }

.wrap {
    position: relative;
    z-index: 100;
    width: 100%;
    height: 100%;
    padding: 0 60px;
    -webkit-background-size: cover;
    background-size: cover;
    overflow: hidden;

    &:after {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; } }

.slider-interior {
    position: relative;
    z-index: 200;
    padding: 0 0px;
    margin: 0 auto;
    width: 100%;

    .card.slick-slide {
        width: 100%;
        height: auto;
        transition: transform .4s;
        position: relative; }


    .slick-slide {
        &:after {
            content: '';
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: transform .4s; }

        img {
            max-width: 100%;
            width: 100%; } }

    .card.slick-slide {
        transform: scale(0.7); }


    .card.slick-slide.slick-center + .slick-slide {
        transform: scale(0.7);
        z-index: 10; }


    .card.slick-slide.slick-center + .slick-slide + .item.slick-slide {
        transform: scale(0.7);
        z-index: 5; }


    .card.slick-slide.slick-center {
        transform: scale(1.2);
        z-index: 20; }


    .slick-center:after {
        opacity: 0; } }

.accordion {
    .accordion-button {
        color: $darkblue-color;
        font-weight: bold;
        padding: 10px; }
    .accordion-body {
        font-size: 17px;
        a {
            color: $mutedblue-color; } } }

.marketing-packages {
    i {
        color: $darkblue-color; }
    .marketing-navs {
        background-color: $lightblue-color;
        .nav-pills {
            .nav-link.active {
                background-color: $darkblue-color;
                h5 {
                    color: white; } } }
        .tab-content {
            .container {
                padding: 0;
                .desc {
                    background-color: $darkblue-color;
                    padding: 10px;
                    color: white;
                    .img-desc {
                        text-align: center; }
                    img {
                        border-radius: 50%;
                        border-style: solid; } }
                li {
                    list-style: inside; }
                .no-bullet-list li {
                    list-style: none; } }
            #marketing-children,#marketing-earlyBuzz,#marketing-kidlit {
                .content {
                    border-top: 3px solid $darkblue-color; } } } }
    .alacarte-intro-text, .alacarte-desc {
        padding: 10px 15px 10px 15px; }

    .marketing-alacarte {
        .alacarte-content {
            .alacarte-desc {
                background-color: $darkblue-color;
                color: white;
                img {
                    border-radius: 50%;
                    border-style: solid; } }
            li {
                list-style: inside; }
            .hidden {
                visibility: hidden; }
            .item-header {
                background-color: #e1e1e1; }
            .prof-item {
                ul {
                    padding: 0; } } } }

    .grey-bg {
        background-color: #e1e1e1; } }


.editing-body {
    .editing-content {
        .services-block-three {
            display: block;
            border: 2px solid #d5d5d51a;
            border-radius: 4px;
            text-align: center;
            background: #fff;
            padding: 10px;
            position: relative;
            margin-bottom: 10px;
            height: 228px;
            width: 350px;
            -webkit-transition: all 200ms ease-in;
            -webkit-transform: scale(1);
            -ms-transition: all 200ms ease-in;
            -ms-transform: scale(1);
            -moz-transition: all 200ms ease-in;
            -moz-transform: scale(1);
            transition: all 100ms ease-in;
            transform: transform .4;

            &:after {
                display: block;
                content: '';
                width: 10%;
                height: 17%;
                position: absolute;
                top: -2px;
                left: -2px;
                border-top: 2px solid $gold-color;
                border-left: 2px solid $gold-color; }

            &:before {
                display: block;
                content: '';
                width: 9%;
                height: 17%;
                position: absolute;
                bottom: -2px;
                right: -2px;
                border-bottom: 2px solid $gold-color;
                border-right: 2px solid $gold-color; }

            &:hover {
                box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
                z-index: 2;
                -webkit-transition: all 200ms ease-in;
                -webkit-transform: scale(1.5);
                -ms-transition: all 200ms ease-in;
                -ms-transform: scale(1.5);
                -moz-transition: all 200ms ease-in;
                -moz-transform: scale(1.5);
                transition: all 200ms ease-in;
                transform: scale(1.1); }

            &:hover:before {
                width: 40%;
                height: 50%;
                transition: all 300ms ease-in; }

            &:hover:after {
                width: 30%;
                height: 40%;
                transition: all 300ms ease-in; } }
        i {
            font-size: x-large;
            color: #D56D86;
            top: 25px;
            margin-top: 25px; }

        h4 {
            font-size: large; }

        h3 {
            text-align: center;
            margin-top: 2em;
            margin-bottom: 1em; } }

    .second-row {
        margin: 0 auto;

        .services-block-three {
            margin: 0 auto;
            margin-bottom: 20px; } } }

.editing-service {

    h3 {
        text-align: center;
        margin-top: 2em;
        margin-bottom: 2em; }
    .row {
        background-color: #f5f6f7;

        .editing-box {
            padding: 5px; } }

    .card {
        border: 1px solid #e3e3e3;

        ul {
            font-size: 16px;
            padding-left: 1.5em;

            ul {
                font-size: 15px;
                padding-left: 1em; } }

        .card-title {
            padding: 5px 20px 3px 20px; }

        .editorial {
            background-color: $brightblue-color; }

        .copy-editing {
            background-color: #D56D86; }

        .substantive {
            background-color: $darkblue-color; }

        .proofreading {
            background-color: $gold-color; }

        .container {
            padding: 5px; } }

    h6 {
        color: whitesmoke; }

    h4 {
        color: white; }


    p {
        font-size: medium; } }

.why-trust-tellwell {

    .title {
        min-height: 100%;
        min-height: 70vh;
        display: flex;
        align-items: center;
        @media screen and (min-width: 320px) and (max-width: 580px) {
            min-height: 0; }

        h3 {
            font-size: xx-large; } }

    .steps-div {
        height: 550px;
        overflow: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            background-color: #F5F5F5; }

        &::-webkit-scrollbar {
            width: 5px;
            background-color: #F5F5F5;
            border-radius: 10px; }

        &::-webkit-scrollbar-thumb {
            background-color: $gold-color;
            border: 2px solid transparent;
            border-radius: 10px; } }

    .stepper {
        .line {
            width: 2px;
            background-color: lightgrey !important; }
        .lead {
            font-size: 1.1rem; }

        .rounded-circle {
            background-color: $gold-color; } } }


.why-book-coaching {

    .title {
        min-height: 100%;
        // min-height: 70vh
        display: flex;
        align-items: center;
        @media screen and (min-width: 320px) and (max-width: 580px) {
            min-height: 0; }

        // Nudge up so it looks more vertically centered:
        position: relative;
        bottom: 15px;

        h3 {
            font-size: xx-large; } }

    .stepper {
        .line {
            width: 2px;
            background-color: lightgrey !important; }
        .lead {
            font-size: 1.1rem; }

        .rounded-circle {
            background-color: $gold-color; } } }

.our-process-editing, .testimonials-editing {
    text-align: center;
    margin-bottom: 6em;

    h3 {
        margin-bottom: 1em;
        margin-top: 1em; } }

.testimonials-editing {
    background-color: #f5f6f7;
    .carousel-testimonials {
        .card {
            margin-top: 2em;
            background-color: #fff;

            .qmark {
                position: absolute;
                top: -30px;
                left: calc(50% - 30px);
                background: #ffffff;
                width: 60px;
                height: 60px;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: Impact, Garamond, Georgia, serif;
                font-size: 3em;
                color: #666666;
                line-height: 60px;
                user-select: none;
                -moz-user-select: none;
                -o-user-select: none;
                -ms-user-select: none;
                border-top: 2px solid #eeeeee; }

            .card-body {
                .author-name {
                    margin-bottom: 0; } } }

        img {
            width: 100%;
            display: initial; }

        .book-title {
            display: flex;
            align-items: center;
            font-style: italic; } } }


.faqs-editing-section {

    .text-indent {
        text-indent: 30px; }

    .accordion-item {
        button {
            z-index: 999; } } }


.book-coach-pricing {
    .package {
        margin-bottom: 5px;
        span.title {
            color: $darkblue-color;
            font-weight: 600;
            font-size: 1.2rem; } }

    .content-column.lite {
        border-left: 2px solid $gold-color; } }

.pairing-with-book-coach {
    background-color: #f5f5dc2e; }

.book-coaching-faqs-section, .book-coach-pricing {
    margin-top: 4.5rem; }

// business author Services

.home-title, h1, h2, h3, h4, h5 {
    font-family: $font-header-main; }

.business-author-header,
.business-author-switchtext {
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 40px;

    @media screen and (max-width: 1024px) {
        height: 300px; }

    @media screen and (max-width: 960px) {
        height: 400px;

        .home-title {
            h1 {
                span {
                    white-space: nowrap; } } }

        padding: 0;
        height: 350px {
            padding: 5px 20px 5px 20px; }
        a {
            .btn {
                font-size: 15px; } } }
    @media screen and (min-width: 320px) and (max-width: 580px) {
        height: 450px; }


    .home-title {
        position: absolute;
        p {
            display: inline-block;
            vertical-align: top; }
        h1 {
            line-height: normal;
            display: inline-block;
            vertical-align: middle; }

        a.home-work-with-us {
            img {
                margin: auto;
                height: 60px;
                width: auto;
                @media screen and (min-width: 320px) and (max-width: 580px) {
                    height: 40px;
                    width: auto; } } }
        .title-below {
            margin-top: -10px; }
        .title-desc {
            font-size: larger; }
        .word-switch,.service-switch {
            position: relative;
            width: 100%;
            opacity: 0;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            .letter.out,.letter.up {
                display: none; } }
        .leader,
        .social-media,
        .entrepreneur,
        .expert,
        .coach {
            color: white; }
        .write {
            color: #D56D86; }
        .promote {
            color: #D56D86; }
        .publish {
            color: $gold-color; }
        .prosper {
            color: #369AAC; }

        .letter {
            position: relative;
            float: revert;
            transform: translateZ(25px);
            transform-origin: 50% 50%;
            &.out {
                transform: rotateX(90deg);
                transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
                float: left; }
            &.behind {
                transform: rotateX(-90deg);
                opacity: 0; }
            &.in {
                transform: rotateX(0deg);
                transition: transform 0.38s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }
            &.empty {
                transform: rotateX(-90deg);
                opacity: 0; } } } }

.business-author-div {
    padding: 10em 2em;
    @media screen and (max-width: 800px) {
        padding: 9em 0px; } }

.business-author-header {
    height: 110px;
    display: inline;
    z-index: 99;
    position: relative;

    .letter {
        display: inline; }

    .home-title {
        h1 {
            font-size: 4em;
            color: #00BFD3;
            padding: 0;

            @media screen and (max-width: 640px) {
                font-size: 55px; }

            @media screen and (max-width: 376px) {
                font-size: 50px; }

            @media screen and (max-width: 330px) {
                font-size: 42px; } } }

    .fixed-headerSwitch {
        @media screen and (min-width: 480px) and (max-width: 1024px) {
            max-width: 100%;
            flex: auto; }

        @media screen and (max-width: 479px) {
            font-size: smaller; } }


    .headerSwitch-div {
        position: absolute;
        float: right;
        text-align: left;
        left: 255px;

        @media screen and (max-width: 479px) {
            left: 215px;
            padding-top: 14px; }

        @media screen and (max-width: 376px) {
            left: 195px; }

        @media screen and (max-width: 330px) {
            left: 160px; }

        ul {
            @media screen and (max-width: 640px) {
                padding-left: 0; } } }


    .wordCarousel,.headerSwitch {
        font-weight: 100;

        div {
            overflow: hidden;
            position: relative;
            float: left;
            height: 100px;
            position: relative;

            @media screen and (max-width: 640px) {
                height: 90px; }

            li {
                font-weight: 700;
                padding: 0 10px 10px 0;
                height: 45px;
                margin-bottom: 45px;
                display: block;

                @media screen and (max-width: 640px) {
                    padding: 0;
                    line-height: 0.8;
                    margin-top: 0px; } } }

        .flip2 {
            animation: flip2 12s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }

        .flip3 {
            animation: flip3 8s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }

        .flip4 {
            animation: flip4 10s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }

        .flip5 {
            animation: flip5 16s cubic-bezier(0.23, 1, 0.32, 1.2) infinite; }


        @keyframes flip2 {
            0% {
                margin-top: -180px; }

            5% {
                margin-top: -90px; }

            50% {
                margin-top: -90px; }

            55% {
                margin-top: 0px; }

            99.99% {
                margin-top: 0px; }

            100% {
                margin-top: -180px; } }

        @keyframes flip3 {
            0% {
                margin-top: -270px; }

            5% {
                margin-top: -180px; }

            33% {
                margin-top: -180px; }

            38% {
                margin-top: -90px; }

            66% {
                margin-top: -90px; }

            71% {
                margin-top: 0px; }

            99.99% {
                margin-top: 0px; }

            100% {
                margin-top: -270px; } }

        @keyframes flip4 {
            0% {
                margin-top: -360px; }

            5% {
                margin-top: -270px; }

            25% {
                margin-top: -270px; }

            30% {
                margin-top: -180px; }

            50% {
                margin-top: -180px; }

            55% {
                margin-top: -90px; }

            75% {
                margin-top: -90px; }

            80% {
                margin-top: 0px; }

            99.99% {
                margin-top: 0px; }

            100% {
                margin-top: -360px; } }

        @keyframes flip5 {
            0% {
                margin-top: -450px; }

            5% {
                margin-top: -360px; }

            20% {
                margin-top: -360px; }

            25% {
                margin-top: -270px; }

            40% {
                margin-top: -270px; }

            45% {
                margin-top: -180px; }

            60% {
                margin-top: -180px; }

            65% {
                margin-top: -90px; }

            80% {
                margin-top: -90px; }

            85% {
                margin-top: 0px; }

            99.99% {
                margin-top: 0px; }

            100% {
                margin-top: -450px; } } } }

.business-author-switchtext {
    height: 130px;
    .letter {
        display: inline-block; }

    @media screen and (max-width: 376px) {
        height: 180px; }

    .home-title, .home-title {
        div {
            @media screen and (max-width: 640px) {
                padding-right: 0; } } }

    .home-title {
        @media screen and (max-width: 640px) {
            padding-right: 0; }

        h1 {
            font-size: 3em;

            @media screen and (max-width: 640px) {
                font-size: 45px; }

            @media screen and (max-width: 330px) {
                font-size: 42px; }

            .mobile-break {
                @media screen and (min-width: 800px) {
                    display: none; } } } } }


.video-wrapper {
    width: 100%;

    .video-div {
        position: relative;
        max-height: 100%;

        .dark-overlay {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 1;
            background: black;
            opacity: 0.4; } }
    video {
        object-fit: cover;
        width: 100%;
        height: 96vh;
        vertical-align: middle;

        @media screen and (max-width: 640px) {
            height: 60vh; } } }

.you-want-div {
    h1 {
        font-size: 50px;

        @media screen and (max-width: 330px) {
            font-size: 42px; } }

    .content-column.title {
        min-height: 100%;
        display: grid;
        align-items: center;
        position: relative;
        bottom: 15px; }

    .carousel-youwanto {
        box-shadow: rgba(0,0,0,.35) 0px 5px 15px;

        .slick-arrow {
            @media screen and (max-width: 800px) {
                display: none !important; } } } }

.you-are-here-div {
    background-color: #F5FFFF;
    @media screen and (min-width: 960px) {
        padding: 1.5rem;
        margin-top: 3rem;

        .mobile-break {
            display: none; } }

    h1 {
        @media screen and (max-width: 330px) {
            font-size: 42px; } } }

.author-business-packages {
    background-color: #dafbff;
    padding: 20px;

    .tab-pane.container.active {
        @media screen and (max-width: 479px) {
            padding: 0; } }

    h1,h2,h3,h4,h5 {
        font: $font-title;
        color: $darkblue-color; }

    .nav-link.active,
    .nav-link.active h5,h4,h3,h2,h1 {
        background-color: $darkblue-color;
        color: white;

        &:hover {
            background-color: $darkblue-color; } }

    .nav-link {
        &:hover {
            background-color: unset; } }

    hr {
        color: $darkblue-color; }

    i {
        color: #00bcd4;
        font-size: 45px;
        margin-left: 5px; }

    .business-price {
        color: $darkblue-color; } }


.testimonials-ab-services-div {
    padding-top: 3em;
    .card {
        margin: 10px;
        margin-top: 5em;
        box-shadow: #efefef 0px 2px 13px 0px;
        background-color: white;


        .img-div {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin: 0 auto;
            margin-top: -4em;

            img {
                background-color: #ffffffd6; } } } }


.carousel-business-logos {
    .card {
        background-color: white;
        height: 200px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        padding: 2em;
        margin: 10px;

        @media screen and (max-width: 479px) {
            padding: 10px;
            margin: 10px;
            height: 150px; } } }
