section.about-us-header {
    .card {
        p {
            font-size: 16px;
            font-style: italic; }

        background-color: #DAFBFF;

        h3 {
            font-family: PT Serif;
            font-weight: 500;
            border-bottom: 3px solid #00BED2; } } }

.about-section {
    position: relative;
    padding: 30px 0 30px;

    img {
        max-width: 100%;
        height: auto;
        box-shadow: 0 30px 50px rgba(8,13,62,.15);
        border-radius: 46px; }

    .inner-column {
        position: relative;
        padding-left: 0px;
        padding-bottom: 0px;

        .image-1 {
            position: relative;

            img {
                box-shadow: 0 30px 50px rgba(8,13,62,.15);
                border-radius: 5px; } }

        .author-desc {
            position: absolute;
            bottom: 8px;
            z-index: 1;
            background: orange;
            padding: 3px 15px;
            left: 95px;
            width: calc(100% - 120px);
            border-radius: 30px;
            @media screen and (min-width: 320px) and (max-width: 580px) {
                bottom: 1px;
                left: 85px; }
            @media screen and (min-width : 768px) and (max-width : 1024px) {
                width: 100%;
                height: 30px;
                left: 0;
                padding: 0;
                bottom: 1px; }

            h2 {
                font-size: 15px;
                letter-spacing: 1px;
                text-align: center;
                color: #fff;
                margin: 0; }

            span {
                font-size: 12px;
                letter-spacing: 3px;
                text-align: center;
                color: #fff;
                display: block;
                font-weight: 400;
                @media screen and (min-width : 768px) and (max-width : 1024px) {
                    margin-top: -5px; } } } }

    .image-column {
        position: relative;

        .text-layer {
            position: absolute;
            right: -110px;
            top: 50%;
            font-size: 325px;
            line-height: 1em;
            color: #ffffff;
            margin-top: -175px;
            font-weight: 500; } }

    .btn-style-one {
        position: relative;
        display: inline-block;
        font-size: 17px;
        line-height: 30px;
        color: #ffffff;
        padding: 10px 30px;
        font-weight: 600;
        overflow: hidden;
        letter-spacing: 0.02em;
        background-color: #00aeef; }

    .btn-style-one {
        &:hover {
            background-color: #0794c9;
            color: #ffffff; } }

    .btn-box {
        position: relative; }


    .btn-box {
        a {
            padding: 15px 50px; } }

    .content-column2 {
        padding-top: 4em; }


    .inner-column-right {
        position: relative;
        padding-left: 0;
        padding-bottom: 0px;

        .image-2 {
            position: relative;

            img {
                box-shadow: 0 30px 50px rgba(8,13,62,.15);
                border-radius: 5px;
                // height: 240px
 } }                // width: 240px


        .author-desc {
            position: absolute;
            bottom: 10px;
            z-index: 1;
            background: orange;
            padding: 1px 15px;
            left: 10px;
            width: calc(100% - 120px);
            border-radius: 30px;

            h2 {
                font-size: 15px;
                letter-spacing: 1px;
                text-align: center;
                color: #fff;
                margin: 0; }

            span {
                font-size: 12px;
                letter-spacing: 3px;
                text-align: center;
                color: #fff;
                display: block;
                font-weight: 400; } } } }

.team-img {
    img {
        vertical-align: middle;
        display: inline-block;
        height: auto;
        max-width: 100%;
        object-fit: cover;

        &:hover {
            cusor: pointer; } } }

.team-title {
  margin: 15px 0 1px; }

.overlay {
  background-color: rgba(20,20,20,.7);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out; }


.team-details {
  opacity: 0;
  position: absolute;
  top: 20%;
  left: 0;
  padding: 5%;
  overflow: hidden;
  width: 100%;
  z-index: 2;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out; }


.team-details {
    p {
        color: #fff;
        font-size: 13px;
        position: absolute; } }

.socials {
    a {
        display: inline-block;
        width: 37px;
        height: 37px;
        background-color: transparent; }

    i {
        line-height: 37px;
        color: #616161;
        font-size: 14px;
        width: 37px;
        height: 37px;
        border-radius: 50%;
        text-align: center;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        -ms-transition: all 0.2s linear;
        transition: all 0.2s linear;
        color: #fff; } }

body.modal-open {
    padding-right: 0 !important; }

#teamDetails.modal.fade.show {
    padding-right: 0 !important;

    .modal-dialog {
        overflow-y: initial !important; }

    .modal-body {
        overflow-y: auto;
        max-height: 60vh;
        padding: 15px;
        text-align: justify;
        white-space: pre-line; } }


.section-1, .section-2,
.section-3, .section-4, .section-5 {
    h3,h4 {
        font-family: "Nunito Sans", sans-serif; }

    h3 {
        text-align: center; }

    h4 {
        font-size: 20px; }
    i {
        color: #00BED2; }

    p {
        font-family: "Nunito Sans",serif;
        font-size: 17px;
        font-weight: 400;
        color: #696969; } }

.section-1 {
    .img-div {
        aspect-ratio: 4 / 3;
        img {
            padding: 0 1em; } }

    .paragraph {
        align-content: center;
        text-align: justify; } }

.section-2 {
    .authors-div {
        padding: 0 5em; }

    .books-published {
        h4 {
            @media only screen and (min-width: 426px) {
                padding-top: 3em; } }

        .books-collage-div {
            padding: 0 3em; } } }

.why-work-with-tellwell-section {
    @media only screen and (min-width: 426px) {
        padding-bottom: 5em; }
    .box {
        @media only screen and (min-width: 376px) {
            padding: 0 3em;

            img {
                border-radius: 6.9px; } } } }

.founding-story {
    @media only screen and (min-width: 1024px) {
        padding: 3em 0 3em 0; } }

.office-profiles-carousel {
    .img-container {
        img {
            object-fit: cover; } } }

.aboutUs-guide-to-self-publishing {
    background-color: #f8f6eb;

    .self-pub-items {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        padding: 15px;
        font-family: "Nunito Sans", sans-serif;


        h3 {
            font-weight: 100;
            font-size: xx-large;
            color: #369aac; }

        .btn {
            border-radius: 20px;
            background-color: #ba7000;
            border-color: yellow;
            color: white; } } }


.authors-slideshow {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease;
    overflow: hidden;
    -webkit-transition: opacity 1s ease;

    .img-container {
        position: relative;
        width: 100%;
        padding-top: 70.25%;
        overflow: hidden;
        display: flex;
        img {
            height: auto;
            width: 100%;
            position: absolute;
            top: 0; } }

    .customized-btns {
        opacity: 0;
        visibility: hidden;
        overflow: hidden;

        .slick-arrow {
            visibility: visible;
            opacity: 1; } } }
.authors-slideshow.slick-initialized {
    visibility: visible;
    opacity: 1; }
