.publishing-packages {
    padding-bottom: 5em;
    .kids-packages .kids-item .item-card,
    .business-packages .business-item .item-card {
        max-width: 220px;
        @media screen and (min-width: 320px) and (max-width: 580px) {
            max-width: 400px;
            width: 350px; }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
            max-width: 310px;
            width: 310px; } }


    h3 {
        font-family: "Nunito Sans", sans-serif;
        color: #369aac;
        font-size: 28px;
        font-weight: 600; }

    img {
        height: 150px;
        width: 100%; }

    .item {
        padding-left: 10px;
        padding-right: 10px; }

    .item-card {
        transition: 0.5s;
        cursor: pointer;
        padding: 0 10px 10px;
        height: auto;
        min-height: 380px;
        border: 2px solid #00bed240;
        border-radius: 0.25rem;
        @media screen and (max-width: 540px) {
            min-height: 280px; }
        i {
            color: #00BCD4;
            font-size: 45px;
            margin-left: 5px; } }

    .item-card-title {
        font-size: 18px;
        cursor: pointer;
        z-index: 1;
        font-weight: 600;
        color: #696969;
        text-align: center; }

    .item-card-title {
        i {
            font-size: 15px;
            transition: 1s;
            cursor: pointer;
            color: #ffa710; } }

    .card {
        &:hover {
            transform: scale(1.05);
            transition: all 200ms ease-in;
            box-shadow: 10px 10px 15px rgba(0,0,0,0.3); }


        .card {
            .txt {
                margin-left: 1rem;

                h1, p {
                    color: rgba(255,255,255,1);
                    opacity: 1; } }

            a {
                color: rgba(255,255,255,1);

                &:after {
                    width: 10%; } } } }

    .card-text {
        height: auto;
        font-size: 16px;
        color: #787777; }


    .card {
        &:before, &:after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: scale3d(0, 0, 1);
            transition: transform .3s ease-out 0s;
            background: rgba(255, 255, 255, 0.1);
            content: '';
            pointer-events: none; } }

    .card {
        &:before {
            transform-origin: left top; } }

    .card {
        &:after {
            transform-origin: right bottom; } }

    .card {
        &:hover:before, &:hover:after, &:focus:before, &:focus:after {
            transform: scale3d(1, 1, 1); } }


    .card-img-overlay {
        top: unset;
        color: #fff;
        background: linear-gradient(0deg, rgba(229,230,228,1) 0%, rgba(242,243,242,1) 14%, rgba(255,255,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001a606f', endColorstr='#e8154159',GradientType=0 );
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 1.25rem; }

    .card-get-started {
        font-weight: 500;
        font-size: 12px;
        color: #00bed2; } }

.package-title {
        font-size: 19px;
        z-index: 1;
        font-weight: 600;
        color: #474646; }

.iconing i, .iconing p {
    display: inline-block;
    vertical-align: middle; }

.iconing p {
    margin-left: 10px;
    margin-bottom: 0; }

.package-features {
    h3 {
        font-family: "Nunito Sans", sans-serif;
        color: #369aac;
        font-size: 28px;
        font-weight: 600; } }

.packages-price {
    color: $darkblue-color;
    font-size: larger; }

.kids-item, .business-item {
    padding-left: 5px;
    padding-right: 5px; }

#tblkids-legend th {
    font-weight: 500; }

.legend-packages {
    p {
        margin-bottom: 0;
        i.legend-check {
            color: #00bed2;
            font-size: 20px; }

        i.fa-plus {
            color: gray; } } }

