.our-books-editorial-picks {
    padding-bottom: 70px;
    h3 {
        padding: 20px 0 20px 0;
        text-align: center;
 } }        // letter-spacing: 2px
.carousel-ourbooks-editorial {
    .sample-item {
        .item {
            margin: 0 auto;
            width: 70%; }
        img {
            height: auto;
            object-fit: cover;
            object-position: top; }
        .card-body {
            p {
                font-size: 17px; } } } }

.our-books-genre {
    .wrapper {
        padding-bottom: 70px; } }


