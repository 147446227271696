.textbluebold {
    color: $mutedblue-color;
    font-weight: bold; }
.textblue {
    color: $brightblue-color; }
.indent {
    margin-left: 40px; }

.legal-content {
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse; }
    td {
        padding: 10px; } }
