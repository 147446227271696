.packages-page-details {
    .package-feature {
        border-bottom: solid 2px $gold-color;
        font-weight: 600; }
    i {
        color: #00bcd4;
        font-size: 70px;
        text-align: center;
        margin-bottom: 10px; }
    i.back-button {
        font-size: 10px;
        text-align: left; }

    .package-price-page {
        color: $darkblue-color;
        font-size: $defaultfont-size; }

    .package-page-title {
        color: #474646;
        font-weight: 600;
        font-size: $mediumfont-size; }

    .packages-page-table {
        i {
            font-size: $defaultfont-size; } } }

.package-page-header {
    font-size: 30px;
    font-weight: 600;
    color: $mutedblue-color; }

.includes-div {
    @media screen and (max-width: 540px) {
        .container {
            padding: 3px; } } }

