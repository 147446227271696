.advocate-section, .ambassador-section, .referral-program-rules-section {
    h3.custom-title, h4.custom-title {
        text-align: center;
        margin-top: 1em; }

    .yellowhighlight {
        // background-color: rgb(255 255 0 / 71%)
        background-color: rgba(255, 255, 0, 0.71); }

    p.text-center {
        margin-bottom: 2em; }

    .text-indent2 {
        text-indent: 2em; }

    .text-justify {
        tex-align: justify; } }
