@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,800;1,400;1,800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
$font-title: 'Nunito Sans', sans-serif;
$font-header-title: 'Nunito Sans', sans-serif;
$font-body: 'Nunito Sans', sans-serif;
$font-header-main: 'Nunito Sans', sans-serif;

$mutedblue-color: #63C9D5;
$darkblue-color: #369aac;
$brightblue-color: #00BFD3;
$gold-color: #FFBD59;
$lightblue-color: #DAFBFF;
$pink-color: #ff86ab;
$defaultfont-size: 16.5px;
$mediumfont-size: 18px;

html, body {
    overflow-x: hidden;
    padding: 0; }

.start-header {
    padding-bottom: 0; }

.nav-link.btn {
    opacity: 1; }

.lets-get-started {
    box-shadow: none;
    font-weight: 600;
    margin-bottom: 0;
    text-align: center;
    background-color: $brightblue-color;
    color: white;
    padding: 10px; }

.dropdown-menu {
    padding: 0;
    margin: 0; }
.navbar {
    @media only screen and (min-width: 1200px) {
        .container {
            max-width: 1400px; } }

    @media only screen and (min-width: 1024px) {
        .nav-item {
            &:hover {
                .dropdown-menu {
                    display: block; } } }

        .tellwell-contactno {
            left: 15px;
            position: relative; } }

    @media only screen and (max-width: 961px) { //for mobile view of navigation
        .nav-item {
            .nav-link {
                border-bottom: 1px solid transparent; }
            .dropdown-toggle {
                text-align: center; }
            .dropdown-menu {
                display: block;
                border: unset;
                text-align: center; } }

        .flag.dropdown {
            ul {
                border: unset;

                a.flag.active {
                    border-bottom: 1px solid transparent; }

                .dropdown-item.active {
                    background-color: rgba(239,239,239,0.5) !important; } } } } }



.navbar {
    .tellwell-contactno {
        a {
            color: $brightblue-color !important; } }
    .nav-item {
        .dropdown-menu {
            display: none; }
        a {
            color: black;
            font-size: smaller;
            text-transform: uppercase;
            letter-spacing: 0.5px; }

        .dropdown-item.active {
            background-color: rgba(0,0,0,0) !important; }

        a.flag {
            font-size: medium; }

        a.flag.active {
            text-decoration: none;
            border-bottom: 2px solid #00bed2;
            background-color: rgba(239, 239, 239 , 0.5) !important; } } }
.sticky {
    position: fixed;
    top: 0;
    width: 100;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    width: 100%;
    box-shadow: 0 2px 5px #6c757d73;
    text-align: center;
    margin: 0 auto;
    z-index: 999; }


.fa-icon {
    color: $brightblue-color; }
.article-post {
    p, ul, ol, table {
        font-size: 16px; } }

.tellwell-logo {
    // width: 100%
    height: auto; }

.tellwell-pank {
    width: 150px;
    height: auto;
    padding-top: 15px; }

.generic-header-title {
    width: 100%;
    height: 30vh;
    position: relative;
    @media screen and (max-width: 540px) {
        height: 20vh;
        text-align: center; }
    img {
        object-fit: cover;
        height: 100%;
        width: 100%; }

    .image-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        // height: 30vh
        height: 100%;
        background-color: rgba(52, 53, 54, 0.5);
        // clip-path: polygon(0 0, 15% 0, 80% 100%, 0% 100%)
        @media screen and (max-width: 540px) {
            height: 20vh; } }

    .container {
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        p {
            margin: 0;
            font-style: italic;
            font-size: 16px;
            color: white;
            @media screen and (min-width: 320px) and (max-width: 580px) {
                font-size: 14px; } }
        h1 {
            color: white;
            text-shadow: 1px 2px #ffbd59;
            text-transform: uppercase;
            font-size: 55px;
            @media screen and (max-width: 540px) {
                font-size: 35px; } } } }

.main-container, .align-fullwidth {
    max-width: 100%;
    padding: 0;
    margin: 0; }

.align-center {
    width: auto;
    margin: 0 auto;
    max-width: 1220px; }

.intro-text {
    padding: 0 20px 0 20px;
    text-align: justify; }

.page-content {
    font-family: $font-body;

    h1,h2,h3,h4,h5 {
        font-family: $font-title;
        color: $darkblue-color; }
    h6 {
        font-family: $font-body;
        font-style: italic;
        color: $brightblue-color; } }

.table-packages, .business-table {
    width: 100%;
    display: table;
    border-collapse: collapse;

    td, th {
        margin: 0;
        padding: 15px;
        line-height: 1.5;
        vertical-align: top;
        border: 1px solid #00bed2d4;
        .fa-check {
            color: #00BCD4; }
        .fa-plus {
            color: grey; } }

    .mainpage-premium {
        @media screen and (min-width: 1281px) {
            width: 379px; } }

    .mainpage-regular {
        @media screen and (min-width: 1281px) {
            width: 410px; } }

    .mainpage-childrens {
        @media screen and (min-width: 1281px) {
            width: 361px; } }

    .thead-fixed {
        @media screen and (min-width: 1024px) {
            position: fixed;
            top: 0;
            width: 100%;
            height: 50px;
            line-height: 3em;
            table-layout: fixed;
            display: table;
            background-color: #fbfeff; } } }

.fixed-table-container {
    position: relative;
    z-index: 0; }

#img-error-404 {
    width: 30%;
    @media screen and (max-width: 540px) {
        width: 100%; } }

.page-header {
    width: 100%;
    height: 30vh;
    position: relative;
    @media screen and (max-width: 540px) {
        height: 20vh;
        text-align: center; }
    img {
        object-fit: cover;
        height: 100%;
        width: 100%; }

    .image-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        // height: 30vh
        height: 100%;
        background-color: rgba(52, 53, 54, 0.5);
        // clip-path: polygon(0 0, 15% 0, 80% 100%, 0% 100%)
        @media screen and (min-width: 320px) and (max-width: 580px) {
            height: 20vh; } }

    .container {
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        p {
            margin: 0;
            font-style: italic;
            font-size: 16px;
            color: white;
            @media screen and (min-width: 320px) and (max-width: 580px) {
                font-size: 14px; } }
        .header-service {
            h1 {
                color: white;
                text-transform: uppercase;
                text-shadow: 1px 2px #ffbd59;
                letter-spacing: 2px;
                font-size: 55px;
                font-family: $font-title;
                font-weight: 700;
                @media screen and (min-width: 580px) and (max-width: 768px) {
                    font-size: 40px; }
                @media only screen and (max-width: 600px) {
                    font-size: 25px; } } }
        .header-package {
            h1 {
                color: white;
                text-shadow: 1px 2px black;
                border-bottom: 3px solid #00BED2;
                @media screen and (min-width: 320px) and (max-width: 580px) {
                    font-size: 35px; } }
            p.title-desc {
                background: radial-gradient(black, transparent); } } } }
footer {
    // background: linear-gradient(0deg, rgba(38,132,143,1) 0%, rgba(130,211,224,1) 100%)
    background-color: #222222fc;
    margin-top: 0 !important;
    padding: 3em 0 !important;

    @media screen and (max-width: 540px) {
        padding: 1em 0 !important; }

    .footer-container {
        margin-bottom: 20px;
        max-width: 1250px;
        @media screen and (min-width: 1900px) {
            max-width: 1500px; }
        @media screen and (max-width: 960px) {
            max-width: 100%; }

        .footer-grid {
            display: grid;
            grid-template-columns: repeat(5, auto);

            @media screen and (max-width: 691px) {
                grid-template-columns: repeat(3, auto); }
            @media screen and (max-width: 430px) {
                grid-template-columns: repeat(2, auto); } }

        .contactUs-div {
            margin-right: 10px;
            @media screen and (min-width: 1024px) {
                text-align: left;
                padding-right: 20px; }

            @media screen and (max-width: 800px) {
                overflow-wrap: break-word; }

            @media screen and (max-width: 540px) {
                padding-bottom: 2em; }

            ul {
                li {
                    line-height: 1.5;
                    padding-bottom: .5em;
                    a {
                        font-family: $font-body;
                        font-size: 17px; }
                    &:hover {
                        color: $brightblue-color; } } } }

        .serviceslink-col {
            ul {
                -webkit-column-count: 2;
                -moz-column-count: 2;
                column-count: 2;
                column-gap: 10px;

                @media screen and (max-width: 800px) {
                    -webkit-column-count: 1;
                    -moz-column-count: 1;
                    column-count: 1; } } } }

    .footer-bottom {
        // color: #5f6161
        // border-top: 2px solid #f0f0f0ab
        padding: 2px;
        border-top: 1px solid #56637d;

        @media screen and (max-width: 540px) {
            text-align: center; }

        .social-media-icons {
            display: flex;
            //justify-content: space-between
            align-items: center;
            gap: 15px;

            @media screen and (max-width: 540px) {
                display: inline-flex;
                padding-top: 2em; }


            img {
                width: 40px;
                height: auto; } }
        .footer-pank-div {
            display: flex;
            justify-content: center; }

        .terms-div {
            padding-top: 1em; }

        .container {
            @media screen and (min-width: 1900px) {
                max-width: 1500px; } } }
    h4 {
        color: #ffffff;
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 1px; } }

.loading-dots-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    text-align: center;
    background-color: #5454546b;
    vertical-align: middle;
    color: #fff;

    .dot {
        height: 20px;
        width: 20px;
        margin-right: 10px;
        border-radius: 10px;
        background-color: $darkblue-color;
        animation: pulse 1.5s infinite ease-in-out;

        &:last-child {
            margin-right: 0; }

        &:nth-child(1) {
            animation-delay: -0.3s; }

        &:nth-child(2) {
            animation-delay: -0.1s; }

        &:nth-child(3) {
            animation-delay: 0.1s; }

        &:after {
            content: ""; } }

    @keyframes pulse {
        0% {
            transform: scale(0.8);
            background-color: $darkblue-color;
            box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7); }

        50% {
            transform: scale(1.2);
            background-color: $mutedblue-color;
            box-shadow: 0 0 0 10px rgba(178, 212, 252, 0); }

        100% {
            transform: scale(0.8);
            background-color: $darkblue-color;
            box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7); } } }

.promo-page-container {
    position: relative;

    .promo-div {
        width: 100%;
        padding: 5px 65px;

        .col {
            box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px; } } }

.cta-guide-to-self-publishing {
    font-family: $font-body;
    background-color: $mutedblue-color;

    .self-pub-items {
        width: 100%;
        margin: 0 auto;
        padding: 15px;
        justify-content: center;

        @media screen and (max-width: 768px) {
            padding: 0; }

        h2 {
            font-weight: 100;
            text-align: center;
            padding: 1em 0 1.5em; }

        .btn {
            border-radius: 20px;
            background-color: #ba7000;
            border-color: yellow;
            color: white; }
        li {
            margin-bottom: 30px;
            font-size: large;
            @media screen and (min-width: 1280px) {
                margin-bottom: 20px; } }

        p {
            font-size: large; }

        .container-row {
            .row {}
            @media screen and (min-width: 1600px) {
                margin: 0 135px auto; } }

        .item-list {
            position: relative;
            left: 20px;
            padding: 0 1.5em 0 6em;
            @media screen and (min-width: 1600px) {
                padding: 0 2em; }
            @media screen and (min-width: 1440px) {
                padding: 0 2em 0 4em;
                left: 0; }
            @media (min-width: 1280px) and (max-width: 1439px) {
                padding: 0 4em 0 2em;
                left: 0; }
            @media screen and (min-width: 1536px) {
                text-align: justify; }
            @media screen and (max-width: 1024px) {
                padding: 0 3em 0 0;
                left: 0; }
            @media screen and (max-width: 800px) {
                padding: 0; }
            @media screen and (max-width: 768px) {
                padding-right: 0; }

            ul {
                padding-left: 4em;
                @media screen and (min-width: 1280px) {
                    padding-left: 2em; }
                @media screen and (max-width: 1024px) {
                    padding-left: 1em; }

                li {
                    text-align: justify;
                    @media screen and (max-width: 1024px) {
                        margin-bottom: 10px; }
                    @media screen and (max-width: 960px) {
                        text-align: left; }
                    @media screen and (max-width: 768px) {
                        font-size: 16px;
                        margin-bottom: 0; } } } }

        .home-book-a-consult {
            width: 1067px;
            height: 100%;
            transform: scale(0.9);
            transform-origin: 0 0;
            overflow: hidden;
            position: relative;
            right: 75px;
            @media screen and (min-width: 1600px) {
                right: 108px; }
            @media screen and (min-width: 1540px) {
                width: 1010px; }
            @media screen and (min-width: 1440px) {
                right: 90px; }
            @media (min-width: 1280px) and (max-width: 1439px) {
                right: 155px; }
            @media screen and (max-width: 1024px) {
                width: 700px;
                right: 60px; }
            @media screen and (max-width: 800px) {
                width: 550px;
                right: 18px; }
            @media screen and (max-width: 768px) {
                width: 532px;
                height: 100%;
                display: flex;
                align-items: center; }
            @media screen and (max-width: 640px) {
                width: 673px; }
            @media screen and (max-width: 500px) {
                width: 442px; }
            @media screen and (max-width: 560px) {
                margin-top: 1em; }
            @media screen and (max-width: 545px) {
                width: 550px; }
            @media screen and (max-width: 440px) {
                width: 474px;
                left: -28px;
                margin-top: 1em; }

            iframe {
                position: absolute;
                top: -63px;
                right: -30px;
                overflow-y: scroll;

                @media screen and (max-width: 768px) {
                    right: 0;
                    padding: 15px; } } }

        .homepage-titles {
            @media screen and (max-width: 1024px) {
                padding: 0 0 1em; } } } }
.guide-iframe {
    #pdfLink {
        padding: 1em; }
    .download-guide-btn {
        background-color: $darkblue-color;
        color: white;
        padding: 10px 20px;
        text-decoration: none;
        border-radius: 5px;
        font-size: 16px;
        position: relative; } }





