#contact-us-signup-scroll {
    font-family: $font-body;
    h2 {
        font-weight: 400;
        font-size: 15px;
        color: #999;
        margin-bottom: 1rem;
        line-height: 1.5;
        text-align: center; }
    .form-group > input, textarea, select {
        width: 100%;
        margin-bottom: .7rem;
        border-radius: 5px;
        border-style: solid; } }

.contact-tellwell-form {
    font-family: $font-body;
    h1 {
        color: $darkblue-color;
        font-family: $font-header-main; }
    .container {
        @media screen and (max-width: 540px) {
            display: block; }
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        .header-text {
            display: none; }
        .left {
            @media screen and (max-width: 540px) {
                width: 100%;
                padding: none; }
            width: 50%;
            height: auto;
            border-radius: 5px;
            padding: 20px 60px 20px 20px; }
        .right {
            @media screen and (max-width: 540px) {
                width: 100%;
                padding: none;
                margin-left: 0; }
            box-shadow: 10px 10px 5px #aaaaaa;
            margin-left: -50px;
            margin-top: 10px;
            width: 50%;
            min-height: 400px;
            background-image: linear-gradient(180deg, $darkblue-color, $brightblue-color);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            padding: 40px;
            h4 {
                color: whitesmoke; } } } }

.contact-methods {
    font-family: $font-body;

    h1 {
        color: $darkblue-color;
        font-family: $font-header-main; }

    .container {
        background-color: $lightblue-color;
        border-radius: 15px;

        ul {
            display: table;

            li {
                list-style: none;
                display: table-row;
                vertical-align: middle;

                .mybullet-middle {
                    display: table-cell;
                    vertical-align: middle; }

                .mybullet-top {
                    display: table-cell;
                    vertical-align: top; }

                .myvalue {
                    display: table-cell;
                    vertical-align: middle; } } }

        i {
            color: $brightblue-color;
            font-size: x-large;
            padding-right: 20px; }

        pre {
            font-family: "Nunito Sans", sans-serif;
            font-size: 17px; } } }

