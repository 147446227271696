.col-custom-12 {
    @media only screen and (max-width: 500px) {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%; } }
.testimonials {
    margin-top: 2em;
    font-family: $font-body;

    .sub-title {
        font-size: 32px;
        @media only screen and (max-width: 1275px) {
            font-size: 30px; } }

    .title-heading {
        font-size: 32px;
        @media only screen and (max-width: 1280px) {
            font-size: 27px; }
        @media only screen and (max-width: 1024px) {
            font-size: 24px; }
        @media only screen and (max-width: 900px) {
            font-size: 22px; }
        @media only screen and (max-width: 700px) {
            font-size: 14px; } }

    .author-name-heading {
        font-size: 32px;
        @media only screen and (max-width: 1280px) {
            font-size: 30px; }
        @media only screen and (max-width: 1024px) {
            font-size: 24px; }
        @media only screen and (max-width: 900px) {
            font-size: 22px; }
        @media only screen and (max-width: 700px) {
            font-size: 14px; } }

    .testimonial-titles {
        font-size: 48px;
        // @media only screen and (max-width: 1191px)
        //     font-size: 50px
        // @media only screen and (max-width: 1092px)
        //     font-size: 47px
        @media only screen and (max-width: 768px) {
            font-size: 30px; } }
    .testimonial-subtitles {
        font-size: 32px;
        // @media only screen and (max-width: 1321px)
        //     font-size: 28px
        @media only screen and (max-width: 1280px) {
            font-size: 27px; }
        @media only screen and (max-width: 1025px) {
            font-size: 24px; }
        @media only screen and (max-width: 800px) {
            font-size: 18px; }
        @media only screen and (max-width: 690px) {
            font-size: 16px; }
        @media only screen and (max-width: 440px) {
            font-size: 14px; } }

    // .wrapper
    //     background-color: #17a2b81a

    .carousel-testimonials {
        width: 100%;
        .card {
            margin-top: 2em;
            background-color: #FFFFFF;
            img {
                width: 50%;
                margin: 0 auto; }

            .card-text {
                font-size: 17px; } } }

    .author-new-testimonials {
        .section1 {
            text-align: center;
            align-center: center;
            .star-container {
                i {
                    color: $gold-color;
                    font-size: xxx-large; } }

            .main-iframe {
                width: 950px;
                height: 550px;
                @media screen and (max-width: 960px) {
                    width: 780px;
                    height: 450px; }
                @media screen and (max-width: 790px) {
                    width: 600px;
                    height: 330px; }
                @media screen and (max-width: 600px) {
                    width: 512px;
                    height: 281px; }
                @media screen and (max-width: 515px) {
                    width: 412px;
                    height: 281px; } }

            .header-video-title {
                @media screen and (min-width: 960px) {
                    font-size: 52px;
                    letter-spacing: 5px;
                    padding: 1em 0 10px 0; } } }

        .video-slider-div {
            margin: 1em 0;
            padding: 0;
            // display: flex
            // align-items: center
            // justify-content: center
            // height: 1800px
            background: white;

            .slanted-background {
                width: 100%;
                height: auto;
                background-color: #282828;
                position: relative;
                // overflow: hidden
                // clip-path: polygon(0 10%, 100% 0%, 100% 89%, 0% 100%)
                z-index: 1;

                .top-slanted-black {
                    position: absolute;
                    top: 0;
                    width: 100%; } }

            .bottom-slanted-black {
                position: relative;
                bottom: 0;
                width: 100%; }

            .video-detail-contents {
                position: relative;
                align-content: end;
                height: 100%;
                margin: 0 auto;
                padding-top: 115px;
                @media screen and (max-width: 500px) {
                    padding-top: 70px; } }

            .author-thumbnail-banner {
                .details {
                    color: white;
                    display: flex;
                    align-content: center;
                    flex-wrap: wrap;
                    // font-size: xx-large
                    text-align: center;
                    left: 115px;
                    align-items: center;
                    height: auto;
                    z-index: 10;
                    line-height: 1.3;
                    @media only screen and (max-width: 1275px) {
                        line-height: 1.4; }
                    @media only screen and (max-width: 1200px) {
                        padding: 0;
                        line-height: 1.3; }
                    @media only screen and (max-width: 900px) {
                        left: 80px; }
                    @media only screen and (max-width: 500px) {
                        left: 48px; }

                    .quotes {
                        @media only screen and (max-width: 700px) {
                            font-size: 14px; }
                        @media only screen and (max-width: 500px) {
                            text-align: justify;
                            line-height: 1.2;
                            margin-bottom: 8px; } }

                    .author-name-heading {
                        color: #00BFD3;
                        font-weight: 600;
                        margin-bottom: 0;
                        line-height: 1.4; }

                    .book-title {
                        color: #00BFD3;
                        font-weight: 600;
                        line-height: 1.4;
                        @media only screen and (max-width: 1440px) {
                            line-height: 1.1; }
                        @media only screen and (max-width: 500px) {
                            margin-bottom: 10px; } }

                    .play-button-div {
                        @media only screen and (max-width: 500px) {
                            margin-bottom: 10px; } }

                    .play-button {
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;
                        border: 3px solid #00d8ff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        margin: 0 auto;
                        @media only screen and (max-width: 1275px) {
                            width: 65px;
                            height: 65px; }
                        @media screen and (max-width: 900px) {
                            width: 60px;
                            height: 60px; }
                        @media screen and (max-width: 600px) {
                            width: 45px;
                            height: 45px; }
                        @media screen and (max-width: 500px) {
                            height: 35px;
                            width: 35px; }

                        .video-pop {
                            &:before {
                                content: "";
                                position: absolute;
                                z-index: 0;
                                left: 50%;
                                top: 50%;
                                transform: translateX(-50%) translateY(-50%);
                                display: block;
                                width: 80px;
                                height: 80px;
                                background: #00e5ff5f;
                                border-radius: 50%;
                                animation: pop-out 1.5s ease-out infinite;
                                animation-fill-mode: forwards;
                                animation-delay: 3s;
                                @media screen and (max-width: 600px) {
                                    width: 40px;
                                    height: 40px; } } }

                        i {
                            color: #00e6ff;
                            border-top: 12px solid transparent;
                            border-bottom: 12px solid transparent;
                            font-size: 50px;
                            @media screen and (max-width: 900px) {
                                font-size: 40px;
                                border-top: 10px solid transparent;
                                border-bottom: 10px solid transparent; }
                            @media screen and (max-width: 600px) {
                                font-size: 30px; }
                            @media screen and (max-width: 500px) {
                                font-size: 18px; } } }

                    @keyframes pop-out {
                        0% {
                            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
                            opacity: 1; }
                        3% {
                            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
                            opacity: 1; }
                        100% {
                            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.9);
                            opacity: 0; } } }

                .img-container {
                    height: 100%;
                    width: auto;
                    overflow: hidden;
                    position: relative;
                    @media screen and (max-width: 1476px) {
                        height: 96%; }
                    @media screen and (max-width: 1428px) {
                        height: auto; }
                    @media screen and (max-width: 500px) {
                        top: 8px; }

                    img {
                        display: block;
                        object-fit: cover;
                        width: 100%;
 } }                        // clip-path: inset(0 0 30% 0)

                .author-img-div {
                    display: flex;
                    align-items: end;
                    align-content: end;
                    flex-wrap: wrap;
                    z-index: 1;
                    transform-origin: center right;
                    transition: transform 0.3s ease;
                    @media screen and (min-width: 1540px) {
                        align-items: center; }
                    @media screen and (max-width: 1428px) {
                        align-content: end;
 }                        // transform: scale(1.2)
                    @media screen and (max-width: 960px) {
                        transform: scale(1.3); }
                    @media screen and (max-width: 768px) {
                        align-content: center;
                        top: 25px; }
                    @media screen and (max-width: 691px) {
                        align-content: end;
                        top: -40px;
                        padding: 0; } }

                #video-popup-container {
                    display: none;
                    position: fixed;
                    z-index: 996;
                    width: 60%;
                    left: 50%;
                    margin-left: -30%;
                    top: 20%;
                    background-color: #fff; }

                #video-popup-close {
                    cursor: pointer;
                    position: absolute;
                    right: -10px;
                    top: -10px;
                    z-index: 998;
                    width: 25px;
                    height: 25px;
                    border-radius: 25px;
                    text-align: center;
                    font-size: 12px;
                    background-color: #000;
                    line-height: 25px;
                    color: #fff; }

                #video-popup-iframe-container {
                    position: absolute;
                    z-index: 997;
                    width: 100%;
                    padding-bottom: 56.25%;
                    border-radius: 2px;

                    #video-popup-iframe {
                        z-index: 999;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        background-color: #000; } }

                #video-popup-overlay {
                    display: none;
                    position: fixed;
                    z-index: 995;
                    top: 0;
                    background-color: #23222296;
                    opacity: 0.8;
                    width: 100%;
                    height: 100%; } }


            .video-container-testimonial {
                position: relative;
                display: inline-block;
                // margin-bottom: 80px
                // position: absolute
                text-align: center;
                z-index: 10;

                iframe {
                    width: 395px;
                    height: 210px;
                    @media screen and (max-width: 1540px) {
                        width: 350px;
                        height: 200px; }
                    @media screen and (max-width: 1428px) {
                        width: 335px;
                        height: 185px; }
                    @media screen and (max-width: 1350px) {
                        width: 315px;
                        height: 180px; }
                    @media screen and (max-width: 1275px) {
                        width: 300px;
                        height: 170px; }
                    @media screen and (max-width: 1200px) {
                        width: 350px;
                        height: 200px; }
                    @media screen and (max-width: 1070px) {
                        width: 330px;
                        height: 180px; }
                    @media screen and (max-width: 1025px) {
                        width: 318px;
                        height: 170px; }
                    @media screen and (max-width: 960px) {
                        width: 300px; }
                    @media screen and (max-width: 900px) {
                        width: 280px; }
                    @media screen and (max-width: 845px) {
                        width: 255px;
                        height: 157px; }
                    @media screen and (max-width: 768px) {
                        width: 245px; }
                    @media screen and (max-width: 650px) {
                        width: 190px;
                        height: 135px; }
                    @media screen and (max-width: 581px) {
                        width: 250px;
                        height: 140px; }
                    @media screen and (max-width: 480px) {
                        width: 200px;
                        height: 120px; } }

                .click-overlay {
                    position: absolute;
                    top: 0;
                    left: 11px;
                    width: 95%;
                    height: 70%;
                    background: transparent;
                    cursor: pointer;
                    z-index: 1;
                    @media screen and (max-width: 1540px) {
                        left: 0;
                        width: 100%; }
                    @media screen and (max-width: 1280px) {
                        left: 15px;
                        width: 98%;
                        height: 68%; }
                    @media screen and (max-width: 1025px) {
                        height: 70%;
                        left: 15px;
                        width: 93%; }
                    @media screen and (max-width: 581px) {
                        height: 70%;
                        width: 95%; }
                    @media screen and (max-width: 480px) {
                        height: 70%;
                        width: 95%;
                        left: 10px; } }
                .card-body {
                    h4 {
                        color: #00BFD3; }
                    .author-name {
                        margin-bottom: 3px;
                        font-size: 21px;
                        @media screen and (max-width: 500px) {
                            font-size: 16px; } }
                    .card-booktitle {
                        font-size: 22px;
                        line-height: 1.2;
                        font-style: italic;
                        @media screen and (max-width: 1024px) {
                            font-size: 18px; }
                        @media screen and (max-width: 768px) {
                            line-height: 1; }
                        @media screen and (max-width: 700px) {
                            font-size: 14px; } } } } }


        .awards-slider {
            opacity: 0;
            visibility: hidden;
            .testimonials-awards-slider {
                padding: 1em;

                .card {
                    display: flex;
                    align-items: center; }
                img {
                    width: 55%;
                    @media screen and (max-width: 1025px) {
                        width: 80%; }
                    @media screen and (max-width: 800px) {
                        width: 90%; } } }
            .left-border, .right-border {
                content: " ";
                display: block;
                position: absolute;
                height: 100%;
                width: 20%;
                z-index: 9;
                top: 0; }

            .left-border {
                left: 0;
                background: linear-gradient(90deg, #ffffff 14.9%, rgba(55, 55, 55, 0.00) 100%); }

            .right-border {
                right: 0;
                background: linear-gradient(-90deg, #ffffff 14.9%, rgba(55, 55, 55, 0.00) 100%); } } }


    .slanted-blue-background, .bottom-slanted-blue {
        width: 100%;
        height: auto;
        position: relative;
        padding: 0;

        img.top-slanted-blue {
            width: 100%;
            height: auto;
            position: relative;
            top: 0;
            z-index: 2; } }

    .your-story-our-mission-div {
        .detail-contents {
            padding-top: 2em;
            background-color: $mutedblue-color;
            z-index: 1;

            .stars-button {
                background-color: $gold-color;
                color: #000;
                border: none;
                border-radius: 50px;
                font-weight: bold;
                text-align: center;
                transition: all 0.3s ease-in-out;
                padding: 9px 65px;
                display: inline-block;
                margin: 0 auto;
                font-size: 34px;
                margin-bottom: 1em;
                @media screen and (max-width: 600px) {
                    padding: 9px 43px;
                    font-size: 28px; } }

            .sub-desc {
                font-size: 30px;
                @media screen and (max-width: 570px) {
                    font-size: 25px;
                    line-height: 1.3; }
                @media screen and (max-width: 570px) {
                    font-size: 18px; } }

            .testimonial-cards-div {
                padding: 2em;
                .categ-title {
                    text-align: center; }
                .fiction-col,.non-fiction-col,.childrens-col {
                    .desc-wrapper {
                        margin: 0 auto;
                        max-height: 600px;
                        overflow: hidden;

                        .author-quote {
                            font-family: $font-body;
                            font-size: 17px;
                            line-height: 1.3; } }

                    .book-category {
                        text-align: center;
                        font-size: 28px;
                        margin-bottom: 1em; }

                    .read-more, .read-less {
                        font-weight: 600;
                        color: #6c6b6b;
                        font-size: 16px; } } }

            .card {
                border-radius: 30px;
                margin-bottom: 20px;
                p {
                    margin: 0; }

                .author-name {
                    text-align: center;
                    font-size: large;
                    font-weight: 600; }

                .card-body {
                    .author-quote {
                        text-align: justify; }
                    .book-title-container {
                        padding: 0;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .book-title {
                            font-weight: 600;
                            font-size: 17px;
                            @media screen and (min-width: 1540px) {
                                font-size: 18px; } } } } } }


        .testimonial-cards-slider-mobile {
            display: none;
            .book-category {
                text-align: center; }
            .book-category.fiction,.book-category.childrens {
                @media screen and (max-width: 600px) {
                    padding-top: 1.3em; } }

            .mobile-category-slider {
                overflow: visible !important;
                // opacity: 1
                // visibility: visible

                .slick-next, .slick-prev {
                    &:before {
                        color: white !important; } } }
            .card {
                margin: 0 10px;
                @media screen and (max-width: 800px) {
                    width: 250px; }

                .card-body {
                    padding: 10px; }

                .author-quote {
                    text-align: justify;
                    font-size: 15px;
                    line-height: 1.3; }

                .book-title {
                    font-size: 15px !important;
                    line-height: 1.1; } } } }


    .trust-pilot-review-div {
        h1 {
            padding-bottom: 20px; }
        .desktop-view {
            box-shadow: 0 -4px 10px #ececec; } }

    .services-testimonial-div {
        .testimonial-titles {
            padding-top: 1em; }
        .testimonial-details {
            margin-bottom: 1em;
            @media only screen and (max-width: 600px) {
                margin-bottom: 2em; }
            .slanted-background-services {
                position: relative;
                background-color: #282828;
                clip-path: polygon(0 12%, 100% 0%, 100% 88%, 0% 100%);
                height: auto;
                @media screen and (max-width: 1540px) {
                    clip-path: polygon(0 9%, 100% 0%, 100% 91%, 0% 100%); }
                @media screen and (max-width: 1362px) {
                    clip-path: polygon(0 14%, 100% 0%, 100% 89%, 0% 100%); }
                @media screen and (max-width: 1092px) {
                    clip-path: polygon(0 11%, 100% 0%, 100% 89%, 0% 100%); }
                @media screen and (max-width: 500px) {
                    clip-path: polygon(0 8%, 100% 0%, 100% 94%, 0% 100%); }
                .top-slanted-black-services {
                    position: absolute;
                    top: 0;
                    width: 100%; }
                .bottom-slanted-black-services {
                    position: relative;
                    bottom: 0;
                    width: 100%; } }

            .customized-btns-testimonials {
                position: absolute;
                bottom: 0;
                @media screen and (max-width: 1540px) {
                    bottom: -16px; }
                @media screen and (max-width: 500px) {
                    bottom: -36px; }

                .prev-arrow,.next-arrow {
                    color: $mutedblue-color; } }

            .detail-contents {
                .img-container {
                    padding: 3em 0 0;
                    @media only screen and (max-width: 1540px) {
                        padding: 0; }
                    @media only screen and (max-width: 1362px) {
                        padding: 0; }
                    img {
                        height: auto;
                        max-width: 100%;
                        width: 80%;
                        @media only screen and (max-width: 1540px) {
                            padding-top: 5px; }
                        @media only screen and (max-width: 1367px) {
                            padding-top: 37px;
                            width: 79%; }
                        @media only screen and (max-width: 1280px) {
                            padding-top: 53px;
                            width: 75%; }
                        @media only screen and (max-width: 440px) {
                            padding-top: 12px;
                            width: 92%; } } }

                // .author-img-container
                //     @media screen and (max-width: 440px)
                //         padding: 0

                .bookslide {
                    position: absolute;
                    bottom: 2em;

                    img {
                        position: relative;
                        width: 25%;
                        @media screen and (max-width: 440px) {
                            width: 28%;
                            bottom: -25px;
                            padding-right: 0; } } }

                .author-quote {
                    color: white;
                    text-align: justify;
                    padding: 0 3em 0 0;
                    font-family: $font-body;
                    line-height: 1.2;
                    @media screen and (max-width: 1440px) {
                        margin-bottom: 0; }
                    @media screen and (max-width: 1360px) {
                        padding: 0 4em 0 3em; }
                    @media screen and (max-width: 1280px) {
                        padding: 0 2em 0 2em; }
                    @media screen and (max-width: 1191px) {
                        padding: 0 1em 0 1em;
                        line-height: 1.3; }
                    @media screen and (max-width: 1130px) {
                        line-height: 1.1; }
                    @media screen and (max-width: 768px) {
                        line-height: 1.2; }
                    @media screen and (max-width: 700px) {
                        line-height: 1.1; }
                    @media screen and (max-width: 655px) {
                        font-size: 17px; }
                    @media screen and (max-width: 600px) {
                        line-height: 1;
                        font-size: 13px; }
                    @media screen and (max-width: 440px) {
                        padding-left: 0;
                        font-size: 12px; } }

                .right-side {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: auto;
                    margin: 0 auto;
                    @media screen and (max-width: 1025px) {
                        left: -20px; }
                    @media screen and (max-width: 620px) {
                        padding-left: 0; }
                    @media screen and (max-width: 500px) {
                        padding-bottom: 2em; }
                    @media screen and (max-width: 445px) {
                        padding: 0; } }
                .title {
                    color: $mutedblue-color;
                    padding-bottom: 5px;
                    @media screen and (max-width: 1540px) {
                        padding-bottom: .5em; }
                    @media screen and (max-width: 1440px) {
                        padding-bottom: 0; } }

                .author-details-container {
                    text-align: center;
                    padding: 1em 0 0 0;
                    @media only screen and (max-width: 1367px) {
                        padding: .6em; }
                    @media only screen and (max-width: 1092px) {
                        padding: 0; }
                    p {
                        color: $mutedblue-color;
                        font-weight: 600;
                        margin-bottom: 0;
                        line-height: 1.4; }
                    @media only screen and (max-width: 440px) {
                        padding: 0; }
                    // p.author-name
                    p.book-title {
                        font-style: italic;
                        margin-bottom: 2em;
                        font-size: 30px;
                        @media screen and (max-width: 1367px) {
                            line-height: 1.3;
                            margin-bottom: 1em;
                            font-size: 27px; }
                        @media screen and (max-width: 1024px) {
                            font-size: 22px; }
                        @media screen and (max-width: 768px) {
                            font-size: 17px; }
                        @media screen and (max-width: 700px) {
                            line-height: 1;
                            font-size: 14px; }
                        @media screen and (max-width: 500px) {
                            margin-bottom: 0;
                            font-size: 11px; } } } } } } }

.testimonials-cta {
    .testimonial-titles {
        font-size: 42px;
        font-weight: 600;
        padding: 1em 0 1.5em;
        @media screen and (max-width: 768px) {
            font-size: 30px; }
        @media screen and (max-width: 500px) {
            padding: 1em 0 .5em; }
        @media screen and (max-width: 440px) {
            font-size: 27px; } }


    .self-pub-items {
        .item-list {
            @media screen and (max-width: 762px) {
                padding: 0px 1.5em; } }
        .home-book-a-consult {
            iframe {
                @media screen and (max-width: 769px) {
                    top: -25px; }
                @media screen and (max-width: 500px) {
                    top: -5px; } } } } }
